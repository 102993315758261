export function localStorageSafelySetItem(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // localStorage не доступен, ничего не делаем
  }
}

export function localStorageSafelyGetItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
}

export function localStorageSafelyRemoveItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // localStorage не доступен, ничего не делаем
  }
}
