import {
  SET_MSISDN,
  REMOVE_MSISDN,
  SET_MANUAL_AUTH,
  SET_SEND_OTP_ERROR_TEXT,
  SET_NEXT_OTP_REQUEST_TIME,
  SET_SEND_PUSH_ERROR_TEXT,
  SET_NEXT_PUSH_REQUEST_TIME,
  SET_LOGIN_FORM_MSISDN,
  SET_AUTH_FETCHED,
} from 'app/constants/action-types';
import { localStorageSafelyGetItem } from 'app/common/helpers/safe-local-storage';

function getMsisdnFromStorage(keyName) {
  const msisdn = localStorageSafelyGetItem(keyName);
  return /^\d{11}$/.test(msisdn) ? msisdn : '';
}

const defaultState = {
  msisdn: getMsisdnFromStorage('auth.msisdn'),
  isManual: localStorageSafelyGetItem('auth.isManual') === 'true',
  code: null,
  sendOtpErrorText: '',
  sendPushErrorText: '',
  nextOtpRequestTime: (parseInt(localStorageSafelyGetItem('auth.nextOtpRequestTime'), 10) || 0),
  nextPushRequestTime: (parseInt(localStorageSafelyGetItem('auth.nextPushRequestTime'), 10) || 0),
  loginFormMsisdn: getMsisdnFromStorage('auth.loginFormMsisdn'),
  isAuthorized: true,
  authFetched: false,
};

const auth = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_MSISDN:
      return { ...state, msisdn: action.msisdn, isAuthorized: true };
    case REMOVE_MSISDN:
      return { ...state, msisdn: null, isAuthorized: false };
    case SET_MANUAL_AUTH:
      return { ...state, isManual: action.isManual };
    case SET_SEND_OTP_ERROR_TEXT:
      return { ...state, sendOtpErrorText: action.errorText };
    case SET_NEXT_OTP_REQUEST_TIME:
      return { ...state, nextOtpRequestTime: action.time };
    case SET_SEND_PUSH_ERROR_TEXT:
      return { ...state, sendPushErrorText: action.errorText };
    case SET_NEXT_PUSH_REQUEST_TIME:
      return { ...state, nextPushRequestTime: action.time };
    case SET_LOGIN_FORM_MSISDN:
      return { ...state, loginFormMsisdn: action.msisdn };
    case SET_AUTH_FETCHED:
      return { ...state, authFetched: true };
    default:
      return state;
  }
};
export default auth;
