import { useEffect, useRef, useState } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Login from 'app/views/login';
import LoginConfirmation from 'app/views/login-confirmation';
import LoginIdentification from 'app/views/login-identification';
import LoginOtp from 'app/views/login-otp';
import LoginConfirmationOtp from 'app/views/login-confirmation-otp';

import makeAbsoluteUrl from 'app/common/helpers/make-absolute-url';
import { getInternalReturnUrl, getPartnerReturnUrl } from 'app/common/helpers/auth';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';

const PARENTAL_CONTROL_SERVICE_URL = 'rk.megafon.ru';

const propTypes = {
  fetchAuth: PropTypes.func.isRequired,
};

const LoginApp = (props) => {
  const { fetchAuth } = props;
  const [dialogTitle, setDialogTitle] = useState(T`Войти в сервис`);
  const [phoneFieldLabel, setPhoneFieldLabel] = useState('Номер телефона');
  const oldTitle = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    oldTitle.current = document.title;

    document.title = 'Войти в сервис';

    if (getPartnerReturnUrl().includes(PARENTAL_CONTROL_SERVICE_URL)) {
      setDialogTitle(T`Родительский контроль`);
      setPhoneFieldLabel('Номер телефона родителя');
    }

    fetchAuth();

    return () => {
      if (oldTitle.current) {
        document.title = oldTitle.current;
      }
    };
  }, []);

  useEffect(() => {
    if (isInsideIframe()) {
      window.parent.postMessage({
        messageId: 'onLocationChange',
        location,
      }, '*');
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="/login"
        element={(
          <Login
            {...{ location, navigate }}
            onClose={close}
            dialogTitle={dialogTitle}
            phoneFieldLabel={phoneFieldLabel}
          />
        )}
      />
      <Route
        path="/login/confirmation"
        element={(
          <LoginConfirmation
            {...{ location, navigate }}
            onClose={close}
            dialogTitle={dialogTitle}
          />
        )}
      />
      <Route
        path="/login-otp"
        element={(
          <LoginOtp
            {...{ location, navigate }}
            onClose={close}
            dialogTitle={dialogTitle}
            phoneFieldLabel={phoneFieldLabel}
          />
        )}
      />
      <Route
        path="/login-otp/confirmation"
        element={(
          <LoginConfirmationOtp
            {...{ location, navigate }}
            onClose={close}
            dialogTitle={dialogTitle}
          />
        )}
      />
      <Route
        path="/login/identification"
        element={(
          <LoginIdentification
            {...{ location }}
            onClose={close}
            dialogTitle={dialogTitle}
          />
        )}
      />
    </Routes>
  );
};

LoginApp.propTypes = propTypes;

export default LoginApp;

function close() {
  if (isInsideIframe()) {
    window.parent.postMessage({
      messageId: 'onClose',
    }, '*');
  } else {
    window.location = getInternalReturnUrl() || getPartnerReturnUrl() || makeAbsoluteUrl('/');
  }
}
