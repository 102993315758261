import addSeconds from 'date-fns/esm/addSeconds';
import fetcher from 'app/common/services/fetcher';
import gtm from 'app/common/services/google-tag-manager';
import {
  localStorageSafelySetItem,
  localStorageSafelyRemoveItem,
} from 'app/common/helpers/safe-local-storage';

import {
  SET_MSISDN,
  SET_MANUAL_AUTH,
  SET_SEND_OTP_ERROR_TEXT,
  SET_NEXT_OTP_REQUEST_TIME,
  SET_SEND_PUSH_ERROR_TEXT,
  SET_NEXT_PUSH_REQUEST_TIME,
  SET_LOGIN_FORM_MSISDN,
  SET_AUTH_FETCHED,
} from 'app/constants/action-types';

import logout, { removeMsisdn } from 'app/action-creators/logout';

export const setMsisdn = (msisdn) => {
  localStorageSafelySetItem('auth.msisdn', msisdn);
  return { type: SET_MSISDN, msisdn };
};

export const setManualAuth = (isManual) => {
  localStorageSafelySetItem('auth.isManual', isManual);
  return { type: SET_MANUAL_AUTH, isManual };
};

export const fetchAuth = () => (dispatch) => {
  fetcher.post('/auth').then((response) => {
    dispatch(setMsisdn(String(response.result.msisdn)));
    dispatch(setManualAuth(response.result.manual_auth));
    dispatch({ type: SET_AUTH_FETCHED });
    if (!response.result.manual_auth) {
      gtm.onAuthByHeader();
    }
  }, (response) => {
    dispatch(removeMsisdn());
    dispatch({ type: SET_AUTH_FETCHED });
    if (response.status === 'authRequired') {
      const currentUrl = window.location.href;
      const paramSeparator = (response.redirectUrl.split('?').length > 1 ? '&' : '?');
      window.location = `${response.redirectUrl}${paramSeparator}return_url=${encodeURIComponent(currentUrl)}`;
    }
  });
};

export const performLogout = () => (dispatch) => {
  fetcher.delete('/logout').catch(() => {});

  dispatch(logout());
};

export const setSendOtpErrorText = (errorText) => ({ type: SET_SEND_OTP_ERROR_TEXT, errorText });

export const setNextOtpRequestTime = () => {
  const seconds = (window.test && window.test.nextOtpRequestTime) || 120;
  const time = addSeconds(new Date(), seconds).valueOf();
  localStorageSafelySetItem('auth.nextOtpRequestTime', time);
  return { type: SET_NEXT_OTP_REQUEST_TIME, time };
};
export const removeNextOtpRequestTime = () => {
  localStorageSafelyRemoveItem('auth.nextOtpRequestTime');
  return { type: SET_NEXT_OTP_REQUEST_TIME, time: 0 };
};

export const setSendPushErrorText = (errorText) => ({ type: SET_SEND_PUSH_ERROR_TEXT, errorText });

export const setNextPushRequestTime = () => {
  const seconds = (window.test && window.test.nextPushRequestTime) || 90;
  const time = addSeconds(new Date(), seconds).valueOf();
  localStorageSafelySetItem('auth.nextPushRequestTime', time);
  return { type: SET_NEXT_PUSH_REQUEST_TIME, time };
};
export const removeNextPushRequestTime = (affectOnlyLocalStorage = false) => (dispatch) => {
  localStorageSafelyRemoveItem('auth.nextPushRequestTime');
  if (affectOnlyLocalStorage) return;
  dispatch({ type: SET_NEXT_PUSH_REQUEST_TIME, time: 0 });
};

export const setLoginFormMsisdn = (msisdn) => {
  localStorageSafelySetItem('auth.loginFormMsisdn', msisdn);
  return { type: SET_LOGIN_FORM_MSISDN, msisdn };
};
export const removeLoginFormMsisdn = (affectOnlyLocalStorage = false) => (dispatch) => {
  localStorageSafelyRemoveItem('auth.loginFormMsisdn');
  if (affectOnlyLocalStorage) return;
  dispatch({ type: SET_LOGIN_FORM_MSISDN, msisdn: null });
};
