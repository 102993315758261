import { Navigate } from 'react-router-dom';
import LoginIdentificationDialog from 'app/components/login-identification-dialog';
import LoginIdentificationForm from 'app/components/login-identification-form';

import { getPartnerRedirectUrl, getPartnerReturnUrl } from 'app/common/helpers/auth';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';
import fetcher from 'app/common/services/fetcher';
import gtm from 'app/common/services/google-tag-manager';

const propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  msisdn: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool,
  isManual: PropTypes.bool,
  performLogout: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  isAuthorized: false,
  isManual: false,
};

class LoginIdentification extends React.Component {
  state = {
    open: true,
    requestInProgress: false,
  };

  onClose = (closingMethod) => {
    if (!this.state.requestInProgress) {
      gtm.onCloseLoginDialog('Вы можете продолжить', closingMethod);
      this.props.onClose();
    }
  };

  onContinue = () => {
    gtm.onLoginIdentificationContinue();
    this.setState({ requestInProgress: true });
    const returnUrl = getPartnerReturnUrl();
    fetcher.post('/partner_auth', { return_url: returnUrl }).then((response) => {
      if (isInsideIframe()) {
        window.parent.postMessage({
          messageId: 'authSuccess',
          sessionId: response.session_id,
        }, '*');
      } else {
        window.location = getPartnerRedirectUrl(returnUrl, response.session_id);
      }
    }, () => {
      this.props.onClose();
    });
  };

  render() {
    const {
      open,
      requestInProgress,
    } = this.state;

    const {
      location,
      dialogTitle,
      isAuthorized,
    } = this.props;

    if (!isAuthorized || (!getPartnerReturnUrl() && !requestInProgress)) {
      return (
        <Navigate
          to={{ ...location, pathname: '/login' }}
          state={location.state}
        />
      );
    }

    return (
      <LoginIdentificationDialog
        isOpen={open}
        onClose={this.onClose}
        dialogTitle={dialogTitle}
      >
        <LoginIdentificationForm
          msisdn={this.props.msisdn}
          canChangeMsisdn={this.props.isManual}
          onChangeMsisdn={() => {
            gtm.onLoginIdentificationChangeMsisdn();
            this.props.performLogout();
          }}
          onContinue={this.onContinue}
          isWaitingResponse={requestInProgress}
        />
      </LoginIdentificationDialog>
    );
  }
}

LoginIdentification.propTypes = propTypes;
LoginIdentification.defaultProps = defaultProps;

export default LoginIdentification;
