import { useLocation } from 'react-router-dom';

import LoginDialog from 'app/components/login-dialog';
import Link from '@kvantera/megafon-ui-components/link';
import Button from '@kvantera/megafon-ui-components/button';

import gtm from 'app/common/services/google-tag-manager';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  resendPush: PropTypes.func.isRequired,
  fallbackToOtp: PropTypes.func.isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  required: false,
  noCloseButton: false,
};

const MobileIdLoginTimeoutErrorDialog = (props) => {
  const {
    isOpen,
    onClose,
    processing,
    resendPush,
    required,
    noCloseButton,
    dialogTitle,
    fallbackToOtp,
  } = props;

  const location = useLocation();

  const handleLoginWithSMSCodeClick = () => {
    gtm.onLoginWithSMSCodeClick();
    fallbackToOtp();
  };

  return (
    <LoginDialog
      id="mobile-id-login-timeout-error-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={(
        <>
          {T`Авторизация по PUSH-сообщению не удалась. Попробуйте еще раз или`}
          {' '}
          <Link
            mods={{
              theme: 'standard',
            }}
            to={{
              ...location,
              pathname: '/login',
              state: { linkId: 'changePhoneLink' },
            }}
          >
            войдите под другим номером
          </Link>
          .
        </>
      )}
      actions={(
        <>
          <Button
            mods={{
              type: 'primary-dialogish',
              disabled: processing,
            }}
            mix={b('mobile-id-login-timeout-error-dialog__resend-option')}
            onClick={resendPush}
            text="Отправить новое Push-сообщение"
          />
          <Button
            mods={{
              type: 'primary',
              wide: true,
              disabled: processing,
            }}
            onClick={handleLoginWithSMSCodeClick}
            text="Войти по SMS-коду"
          />
        </>
      )}
    />
  );
};

MobileIdLoginTimeoutErrorDialog.propTypes = propTypes;
MobileIdLoginTimeoutErrorDialog.defaultProps = defaultProps;

export default MobileIdLoginTimeoutErrorDialog;
