import LoginDialog from 'app/components/login-dialog';
import FormattedMsisdn from '@kvantera/megafon-ui-components/formatted-msisdn';

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  msisdn: PropTypes.string,
};

const defaultProps = {
  required: false,
  noCloseButton: false,
  msisdn: undefined,
};

const OtpLoginConfirmationDialog = (props) => {
  const {
    children,
    isOpen,
    onClose,
    required,
    noCloseButton,
    dialogTitle,
    msisdn,
  } = props;

  return (
    <LoginDialog
      id="otp-login-confirmation-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={!!msisdn && (
        <>
          {T`Мы отправили SMS с кодом на номер`}
          <br/>
          <FormattedMsisdn msisdn={msisdn}/>
        </>
      )}
      form={children}
    />
  );
};

OtpLoginConfirmationDialog.propTypes = propTypes;
OtpLoginConfirmationDialog.defaultProps = defaultProps;

export default OtpLoginConfirmationDialog;
