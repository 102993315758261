import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import removeTrailingSlash from '@kvantera/ui-helpers/remove-trailing-slash';

import LoginApp from 'app/components/login-app';
import GtmLinkTracker from 'app/components/gtm-link-tracker';

import store from './app.store';

const sandbox = () => {
  if (ENV !== 'production') {
    const SandboxApp = require('sandbox/components/sandbox-app').default; // eslint-disable-line global-require

    return <Route path="sandbox/*" element={<SandboxApp/>}/>;
  }

  return null;
};

const LoginStates = () => (
  <Provider store={store}>
    <BrowserRouter basename={removeTrailingSlash(BASE_PATH)}>
      <Routes>
        {sandbox()}
        <Route path="*" element={<LoginApp/>}/>
      </Routes>
      <GtmLinkTracker/>
    </BrowserRouter>
  </Provider>
);

export default LoginStates;
