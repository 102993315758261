import { connect } from 'react-redux';
import withFetcher from 'app/common/helpers/with-fetcher';

import {
  setLoginFormMsisdn,
  setSendPushErrorText,
  setNextPushRequestTime,
  setSendOtpErrorText,
  setNextOtpRequestTime,
} from 'app/action-creators/auth';

import Login from './login';

const mapStateToProps = state => ({
  loginFormMsisdn: state.auth.loginFormMsisdn,
  sendPushErrorText: state.auth.sendPushErrorText,
  isAuthorized: !!state.auth.msisdn,
  nextPushRequestTime: state.auth.nextPushRequestTime,
  setSendOtpErrorText: state.auth.setSendOtpErrorText,
  nextOtpRequestTime: state.auth.nextOtpRequestTime,
});

const mapDispatchToProps = {
  setLoginFormMsisdn,
  setSendPushErrorText,
  setNextPushRequestTime,
  setSendOtpErrorText,
  setNextOtpRequestTime,
};

export default withFetcher(connect(mapStateToProps, mapDispatchToProps)(Login));
