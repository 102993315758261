function parseURLParams(urlSearch) {
  return urlSearch
    .substr(urlSearch.indexOf('?'), urlSearch.length)
    .substr(1)
    .split('&')
    .reduce((acc, paramString) => {
      const [paramName, paramValue] = paramString.split('=');
      acc[decodeURIComponent(paramName)] = decodeURIComponent(paramValue);
      return acc;
    }, {});
}

export default parseURLParams;
