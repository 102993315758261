import { Navigate } from 'react-router-dom';
import OtpLoginConfirmationDialog from 'app/components/otp-login-confirmation-dialog';
import SmsCodeConfirmationForm from 'app/components/sms-code-confirmation-form';
import OtpLoginExceededErrorDialog from 'app/components/otp-login-exceeded-error-dialog';
import OtpLoginGeneralErrorDialog from 'app/components/otp-login-general-error-dialog';
import { getPartnerRedirectUrl, getPartnerReturnUrl } from 'app/common/helpers/auth';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';
import gtm from 'app/common/services/google-tag-manager';

const propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  loginFormMsisdn: PropTypes.string,
  sendOtpErrorText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setManualAuth: PropTypes.func.isRequired,
  setMsisdn: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
  nextOtpRequestTime: PropTypes.number.isRequired,
  setNextOtpRequestTime: PropTypes.func.isRequired,
  removeNextOtpRequestTime: PropTypes.func.isRequired,
  removeLoginFormMsisdn: PropTypes.func.isRequired,
  fetcher: PropTypes.shape({
    post: PropTypes.func.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  changePhoneUrl: PropTypes.string.isRequired,
};

const defaultProps = {
  loginFormMsisdn: undefined,
  isAuthorized: false,
  required: false,
  noCloseButton: false,
};

const LOGIN_CONFIRMATION_DIALOG = 'LOGIN_CONFIRMATION_DIALOG';
const LOGIN_EXCEEDED_ERROR_DIALOG = 'LOGIN_EXCEEDED_ERROR_DIALOG';
const LOGIN_GENERAL_ERROR_DIALOG = 'LOGIN_GENERAL_ERROR_DIALOG';

class LoginConfirmationOtp extends React.Component {
  state = {
    dialogId: LOGIN_CONFIRMATION_DIALOG,
    errorText: this.props.sendOtpErrorText,
    code: '',
    isCodeCorrect: false,
    isAttemptPossible: true,
    isResendLimitReached: !!this.props.sendOtpErrorText,
    processing: false,
    invalidCodeInputCounter: 0,
  };

  componentDidMount() {
    const {
      loginFormMsisdn,
      location,
      navigate,
      sendOtpErrorText,
    } = this.props;

    if (!loginFormMsisdn) {
      navigate({
        ...location,
        pathname: location.pathname.replace('/confirmation', ''),
      }, {
        state: location.state,
      });
    }

    if (sendOtpErrorText) {
      gtm.onLoginConfirmationError(sendOtpErrorText);
    }
  }

  handleSmsCodeChange = (code) => {
    this.setState({
      code,
      errorText: '',
    });
  };

  authorize = (code) => {
    const onLogin = () => {
      gtm.onLoginConfirmationSuccess('sms');
      // задержка добавлена для того, чтобы показать пользователю поле ввода кода в состоянии успеха перед редиректом
      setTimeout(() => {
        this.props.setMsisdn(this.props.loginFormMsisdn);
        this.props.removeLoginFormMsisdn();

        this.props.setManualAuth(true);

        // true значит, что пользователь залогинился
        this.props.onClose(true);
      }, 100);
    };

    this.setState({
      code,
      processing: true,
    });
    code = code.replace(/\D/g, ''); // eslint-disable-line no-param-reassign
    this.props.fetcher.post('/otp_auth', { msisdn: this.props.loginFormMsisdn, code }).then(() => {
      this.setState({
        isCodeCorrect: true,
        processing: false,
      });
      this.props.removeNextOtpRequestTime();
      const returnUrl = getPartnerReturnUrl();
      if (returnUrl) {
        this.props.fetcher.post('/partner_auth', { return_url: returnUrl }).then((response) => {
          gtm.onLoginConfirmationSuccess('sms');
          // Удаляем loginFormMsisdn из localStorage, но оставляем в redux. Значение в redux нельзя удалять,
          // так как иначе во время выполнения редиректа компонент перерисовывается с пустым значением loginFormMsisdn
          // и выглядит неправильно.
          this.props.removeLoginFormMsisdn(true);
          if (isInsideIframe()) {
            window.parent.postMessage({
              messageId: 'authSuccess',
              sessionId: response.session_id,
            }, '*');
          } else {
            window.location = getPartnerRedirectUrl(returnUrl, response.session_id);
          }
        }, () => {
          onLogin();
        });
      } else {
        onLogin();
      }
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        invalidCode: 'Введен неверный код подтверждения',
        tooManyRequests: 'Вы превысили количество попыток авторизации',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      gtm.onLoginConfirmationError(errorText);
      switch (errorResponse.status) {
        case 'invalidCode':
          this.setState((prevState) => {
            if (prevState.invalidCodeInputCounter < 2) {
              return {
                errorText,
                isCodeCorrect: false,
                processing: false,
                isAttemptPossible: true,
                invalidCodeInputCounter: prevState.invalidCodeInputCounter + 1,
              };
            }
            return {
              dialogId: LOGIN_GENERAL_ERROR_DIALOG,
              isCodeCorrect: false,
              processing: false,
              isAttemptPossible: true,
              invalidCodeInputCounter: 0,
            };
          });
          break;
        case 'tooManyRequests':
          this.setState({
            dialogId: LOGIN_EXCEEDED_ERROR_DIALOG,
            isCodeCorrect: false,
            processing: false,
            isAttemptPossible: false,
          });
          break;
        default:
          this.setState({
            dialogId: LOGIN_GENERAL_ERROR_DIALOG,
            isCodeCorrect: false,
            processing: false,
            isAttemptPossible: true,
          });
          break;
      }
    });
  };

  resendOtp = () => {
    gtm.onResendClick('Отправить код повторно');
    this.setState({
      errorText: '',
      processing: true,
      dialogId: LOGIN_CONFIRMATION_DIALOG,
      code: '',
    });
    const params = { msisdn: this.props.loginFormMsisdn };
    const returnUrl = getPartnerReturnUrl();
    if (returnUrl) {
      params.return_url = returnUrl;
    }
    this.props.fetcher.post('/send_otp', params).then(() => {
      this.props.setNextOtpRequestTime();
      this.setState({ processing: false });
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов кода подтверждения',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      this.setState({
        errorText,
        processing: false,
        isResendLimitReached: errorResponse.status === 'tooManyRequests',
      });
      gtm.onLoginConfirmationError(errorText);
    });
  };

  render() {
    const {
      dialogId,
      code,
      isCodeCorrect,
      processing,
      errorText,
      isAttemptPossible,
      isResendLimitReached,
    } = this.state;

    const {
      isAuthorized,
      onClose,
      loginFormMsisdn,
      nextOtpRequestTime,
      required,
      noCloseButton,
      dialogTitle,
      changePhoneUrl,
    } = this.props;

    const returnUrl = getPartnerReturnUrl();

    return (
      <>
        {
          (isAuthorized && !isCodeCorrect) ? (
            <Navigate to="/"/>
          ) : (
            <>
              <OtpLoginConfirmationDialog
                isOpen={dialogId === LOGIN_CONFIRMATION_DIALOG}
                onClose={closingMethod => {
                  gtm.onCloseLoginDialog('Код из sms', closingMethod);
                  onClose();
                }}
                required={required}
                noCloseButton={noCloseButton}
                dialogTitle={dialogTitle}
                msisdn={loginFormMsisdn}
              >
                <SmsCodeConfirmationForm
                  msisdn={loginFormMsisdn}
                  isAttemptPossible={isAttemptPossible}
                  isResendLimitReached={isResendLimitReached}
                  onChange={this.handleSmsCodeChange}
                  onFilled={this.authorize}
                  errorText={errorText}
                  isCodeCorrect={isCodeCorrect}
                  newCodeRequestTime={nextOtpRequestTime}
                  code={code}
                  onResend={this.resendOtp}
                  processing={processing}
                />
              </OtpLoginConfirmationDialog>

              <OtpLoginExceededErrorDialog
                isOpen={dialogId === LOGIN_EXCEEDED_ERROR_DIALOG}
                onClose={onClose}
                required={required}
                noCloseButton={noCloseButton}
                dialogTitle={dialogTitle}
                returnUrl={returnUrl}
                changePhoneUrl={changePhoneUrl}
              />

              <OtpLoginGeneralErrorDialog
                isOpen={dialogId === LOGIN_GENERAL_ERROR_DIALOG}
                onClose={onClose}
                required={required}
                noCloseButton={noCloseButton}
                dialogTitle={dialogTitle}
                onResend={this.resendOtp}
                changePhoneUrl={changePhoneUrl}
              />
            </>
          )
        }
      </>
    );
  }
}

LoginConfirmationOtp.propTypes = propTypes;
LoginConfirmationOtp.defaultProps = defaultProps;

export default LoginConfirmationOtp;
