import Button from '@kvantera/megafon-ui-components/button';
import Timer from '@kvantera/core-ui-components/timer';

const propTypes = {
  pauseEndTime: PropTypes.number,
  onResend: PropTypes.func,
};

const defaultProps = {
  pauseEndTime: null,
  onResend: undefined,
};

class SmsCodeFormField__ResendOption extends React.Component {
  render() {
    const {
      pauseEndTime,
    } = this.props;

    return (
      <div className={b('sms-code-form-field__resend-option')}>
        {
          (Date.now() < pauseEndTime) ? (
            <p className={b('sms-code-form-field__resend-timer')}>
              Отправить новый код (
              <Timer
                endTime={pauseEndTime}
                onEnd={() => this.forceUpdate()}
              />
              )
            </p>
          ) : (
            <Button
              mods={{ type: 'primary-dialogish' }}
              onClick={this.props.onResend}
              mix={[b('sms-code-form-field__resend-button')]}
            >
              Отправить код повторно
            </Button>
          )
        }
      </div>
    );
  }
}

SmsCodeFormField__ResendOption.propTypes = propTypes;
SmsCodeFormField__ResendOption.defaultProps = defaultProps;

export default SmsCodeFormField__ResendOption;
