import SvgIcon from '@kvantera/core-ui-components/svg-icon';
import TelField from '@kvantera/megafon-ui-components/tel-field';
import Form, {
  Form__Field,
  Form__Actions,
} from 'app/components/form';
import FormField from 'app/components/form-field';
import Button from '@kvantera/megafon-ui-components/button';
import Link from '@kvantera/megafon-ui-components/link';
import MobileIdIcon from 'app/assets/images/mobile-id-icon.svg';

const VALID_MSISDN_LENGTH = 11;
const TERMS_OF_SERVICE_LINK_TEXT = 'условиями предоставления сервиса «Мобильный ID»';

const propTypes = {
  onSubmit: PropTypes.func,
  processing: PropTypes.bool,
  errorText: PropTypes.string,
  phoneFieldLabel: PropTypes.string.isRequired,
  onTermsOfServiceLinkClick: PropTypes.func,
};

const defaultProps = {
  onSubmit: undefined,
  processing: false,
  errorText: undefined,
  onTermsOfServiceLinkClick: undefined,
};

class MobileIdLoginForm extends React.Component {
  state = {
    valid: false,
    phone: '',
  };

  msisdn = '';

  onTermsOfServiceLinkClick = () => {
    const { onTermsOfServiceLinkClick } = this.props;
    if (onTermsOfServiceLinkClick) {
      onTermsOfServiceLinkClick(TERMS_OF_SERVICE_LINK_TEXT);
    }
  };

  onPhoneNumberChange = (phone) => {
    this.msisdn = phone.replace(/\D/g, '');
    this.setState({
      phone,
      valid: this.isValid(),
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.props.onSubmit) this.props.onSubmit(this.msisdn);
  };

  isValid() {
    return this.msisdn.length === VALID_MSISDN_LENGTH;
  }

  render() {
    const {
      valid,
      phone,
    } = this.state;

    const {
      errorText,
      processing,
      phoneFieldLabel,
    } = this.props;

    return (
      <Form
        mods={{
          theme: 'standard',
          for: 'login',
        }}
        mix={b('mobile-id-login-form')}
        onSubmit={this.onSubmit}
      >
        <Form__Field>
          <FormField
            id="phone-number-input"
            caption={phoneFieldLabel}
            mods={{
              validationState: errorText ? 'error' : undefined,
              hiddenCaption: true,
            }}
            error={errorText}
          >
            <TelField
              mods={{
                validationState: errorText ? 'error' : (valid && 'success') || undefined,
              }}
              value={phone}
              onChange={this.onPhoneNumberChange}
            />
          </FormField>
        </Form__Field>

        <Form__Actions>
          <Button
            mods={{
              type: 'primary',
              wide: true,
              iconed: true,
              disabled: !valid || processing,
            }}
            icon={{
              content: <SvgIcon mix={b('mobile-id-login-form__action-icon')} symbol={MobileIdIcon.id} width={17} height={20}/>,
            }}
            mix={b('mobile-id-login-form__action')}
            type="submit"
            text="Войти"
          />
        </Form__Actions>
        <p className={b('mobile-id-login-form__conditions')}>
          {T`Нажимая на кнопку «Войти», вы соглашаетесь с`}
          {' '}
          <Link
            mods={{
              theme: 'context',
              external: true,
            }}
            href="https://www.megalabs.ru/uploads/files/offermobile-id-b2c.pdf"
            onClick={this.onTermsOfServiceLinkClick}
          >
            {TERMS_OF_SERVICE_LINK_TEXT}
          </Link>
          .
        </p>
      </Form>
    );
  }
}

MobileIdLoginForm.propTypes = propTypes;
MobileIdLoginForm.defaultProps = defaultProps;

export default MobileIdLoginForm;
