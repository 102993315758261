import LoginDialog from 'app/components/login-dialog';
import Timer from '@kvantera/core-ui-components/timer';
import Throbber from '@kvantera/megafon-ui-components/throbber';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  newMessageRequestTime: PropTypes.number.isRequired,
  onTimerReached: PropTypes.func,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  onTimerReached: () => undefined,
  required: false,
  noCloseButton: false,
};

class MobileIdLoginConfirmationDialog extends React.Component {
  onTimerEnd = () => {
    this.props.onTimerReached();
  };

  render() {
    const {
      isOpen,
      onClose,
      newMessageRequestTime,
      required,
      noCloseButton,
      dialogTitle,
    } = this.props;

    return (
      <LoginDialog
        id="mobile-id-login-confirmation-dialog"
        isOpen={isOpen}
        onClose={onClose}
        required={required}
        noCloseButton={noCloseButton}
        dialogTitle={dialogTitle}
        description={T`Мы отправили вам PUSH-сообщение. Примите его на вашем устройстве, чтобы войти в сервис.`}
        actions={(
          <>
            <p className={b('mobile-id-login-confirmation-dialog__resend-timer')}>
              Отправить новое Push-сообщение (
              <Timer
                endTime={newMessageRequestTime}
                onEnd={this.onTimerEnd}
              />
              )
            </p>
            <div className={b('mobile-id-login-confirmation-dialog__throbber')}>
              <Throbber/>
            </div>
          </>
        )}
      />
    );
  }
}

MobileIdLoginConfirmationDialog.propTypes = propTypes;
MobileIdLoginConfirmationDialog.defaultProps = defaultProps;

export default MobileIdLoginConfirmationDialog;
