import { Navigate } from 'react-router-dom';
import OtpLoginDialog from 'app/components/otp-login-dialog';
import gtm from 'app/common/services/google-tag-manager';
import { getPartnerReturnUrl } from 'app/common/helpers/auth';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  loginFormMsisdn: PropTypes.string,
  setLoginFormMsisdn: PropTypes.func.isRequired,
  setSendOtpErrorText: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
  nextOtpRequestTime: PropTypes.number.isRequired,
  setNextOtpRequestTime: PropTypes.func.isRequired,
  fetcher: PropTypes.shape({
    post: PropTypes.func.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  phoneFieldLabel: PropTypes.string.isRequired,
};

const defaultProps = {
  loginFormMsisdn: undefined,
  isAuthorized: false,
  required: false,
  noCloseButton: false,
};

class LoginOtp extends React.Component {
  state = {
    open: true,
    errorText: '',
    processing: false,
  };

  onPhoneNumberChange = () => {
    this.setState({ errorText: '' });
  };

  onSubmit = (msisdn) => {
    const { location } = this.props;

    const goToConfirmation = (newOtpRequested, errorText = '') => {
      gtm.onLoginSuccess();
      if (newOtpRequested) {
        this.props.setNextOtpRequestTime();
        this.props.setLoginFormMsisdn(msisdn);
      }
      this.props.setSendOtpErrorText(errorText);
      this.props.navigate({ ...location, pathname: `${location.pathname}/confirmation` }, { state: location.state });
    };

    if (msisdn === this.props.loginFormMsisdn && Date.now() < this.props.nextOtpRequestTime) {
      goToConfirmation(false);
      return;
    }

    this.setState({ errorText: '', processing: true });
    const params = { msisdn };
    const returnUrl = getPartnerReturnUrl();
    if (returnUrl) {
      params.return_url = returnUrl;
    }
    this.props.fetcher.post('/send_otp', params).then(() => {
      goToConfirmation(true);
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов кода подтверждения',
      };
      if (errorResponse.status === 'tooManyRequests') {
        goToConfirmation(true, errors.tooManyRequests);
      } else {
        const errorText = errors[errorResponse.status] || 'Ошибка выполнения запроса';
        this.setState({ errorText, processing: false });
        gtm.onLoginError(errorText);
      }
    });
  };

  render() {
    const {
      location,
      isAuthorized,
      onClose,
      required,
      noCloseButton,
      dialogTitle,
      phoneFieldLabel,
    } = this.props;

    const {
      open,
      errorText,
      processing,
    } = this.state;

    if (isAuthorized) {
      if (getPartnerReturnUrl()) {
        return (
          <Navigate
            to={{ ...location, pathname: '/login/identification' }}
            state={location.state}
          />
        );
      }
      return <Navigate to="/"/>;
    }

    return (
      <OtpLoginDialog
        isOpen={open}
        onClose={closingMethod => {
          gtm.onCloseLoginDialog('Номер телефона', closingMethod);
          onClose();
        }}
        onPhoneNumberChange={this.onPhoneNumberChange}
        onSubmit={this.onSubmit}
        errorText={errorText}
        processing={processing}
        required={required}
        noCloseButton={noCloseButton}
        dialogTitle={dialogTitle}
        phoneFieldLabel={phoneFieldLabel}
      />
    );
  }
}

LoginOtp.propTypes = propTypes;
LoginOtp.defaultProps = defaultProps;

export default LoginOtp;
