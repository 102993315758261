import parseURLParams from 'app/common/helpers/parse-url-params';

export function getPartnerRedirectUrl(returnUrl, sessionId) {
  const urlParts = returnUrl.split('#');
  const urlWithoutHash = urlParts[0];
  const hash = urlParts[1] ? `#${urlParts[1]}` : '';
  const paramSeparator = returnUrl.indexOf('?') >= 0 ? '&' : '?';

  const url = `${urlWithoutHash}${paramSeparator}session_id=${encodeURIComponent(sessionId)}${hash}`;

  return url;
}

export function getPartnerReturnUrl() {
  const params = parseURLParams(window.location.search);
  return params.return_url || '';
}

export function getInternalReturnUrl() {
  const params = parseURLParams(window.location.search);
  return params.internal_return_url || '';
}
