import { useLocation } from 'react-router-dom';

import LoginDialog from 'app/components/login-dialog';
import Link from '@kvantera/megafon-ui-components/link';
import Button from '@kvantera/megafon-ui-components/button';

import gtm from 'app/common/services/google-tag-manager';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fallbackToOtp: PropTypes.func.isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  processing: PropTypes.bool,
};

const defaultProps = {
  required: false,
  noCloseButton: false,
  processing: false,
};

const MobileIdLoginGeneralErrorDialog = (props) => {
  const {
    isOpen,
    onClose,
    required,
    noCloseButton,
    dialogTitle,
    processing,
    fallbackToOtp,
  } = props;

  const location = useLocation();

  const handleLoginWithSMSCodeClick = () => {
    gtm.onLoginWithSMSCodeClick();
    fallbackToOtp();
  };

  return (
    <LoginDialog
      id="mobile-id-login-general-error-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={(
        <>
          {T`Авторизация по PUSH-сообщению не удалась. Попробуйте войти по SMS-коду или`}
          {' '}
          <Link
            mods={{
              theme: 'standard',
            }}
            to={{ ...location, pathname: '/login' }}
          >
            изменить номер
          </Link>
          .
        </>
      )}
      actions={(
        <Button
          mods={{
            type: 'primary',
            wide: true,
            disabled: processing,
          }}
          mix={b('mobile-id-login-general-error-dialog__action')}
          onClick={handleLoginWithSMSCodeClick}
          text="Войти по SMS-коду"
        />
      )}
    />
  );
};

MobileIdLoginGeneralErrorDialog.propTypes = propTypes;
MobileIdLoginGeneralErrorDialog.defaultProps = defaultProps;

export default MobileIdLoginGeneralErrorDialog;
