/* eslint-disable react/jsx-filename-extension */

import { CancelToken } from 'axios';
import fetcher from 'app/common/services/fetcher';

export default function withFetcher(WrappedComponent) {
  class WithFetcher extends React.Component {
    constructor(props) {
      super(props);

      this.fetcher = {};

      const cancelToken = new CancelToken((cancel) => {
        this.cancelRequests = cancel;
      });

      ['get', 'post', 'put', 'patch', 'delete', 'head'].forEach((method) => {
        this.fetcher[method] = (url, body, heads) => fetcher[method](url, body, heads, cancelToken);
      });
    }

    componentWillUnmount() {
      this.cancelRequests('Component has been unmounted');
    }

    render() {
      return (
        <WrappedComponent fetcher={this.fetcher} {...this.props}/>
      );
    }
  }
  WithFetcher.displayName = `withFetcher(${WrappedComponent.displayName || WrappedComponent.name})`;
  return WithFetcher;
}
