import Form, { Form__Field } from 'app/components/form';
import SmsCodeFormField from 'app/components/sms-code-form-field';

const propTypes = {
  isCodeCorrect: PropTypes.bool,
  errorText: PropTypes.string,
  newCodeRequestTime: PropTypes.number,
  isAttemptPossible: PropTypes.bool,
  isResendLimitReached: PropTypes.bool,
  onChange: PropTypes.func,
  onFilled: PropTypes.func,
  code: PropTypes.string,
  onResend: PropTypes.func.isRequired,
  processing: PropTypes.bool,
};

const defaultProps = {
  isCodeCorrect: false,
  errorText: '',
  newCodeRequestTime: null,
  isAttemptPossible: false,
  isResendLimitReached: false,
  onChange: undefined,
  onFilled: undefined,
  code: undefined,
  processing: false,
};

class SmsCodeConfirmationForm extends React.Component {
  onChange = (code) => {
    if (this.props.onChange) this.props.onChange(code);

    if (isFilled(code)) {
      this.onFilled(code);
    }
  };

  onFilled = (code) => {
    if (this.props.onFilled) {
      this.props.onFilled(code);
    }
  };

  render() {
    const {
      isCodeCorrect,
      isAttemptPossible,
      isResendLimitReached,
      newCodeRequestTime,
      code,
      onResend,
      errorText,
      processing,
    } = this.props;

    const filled = code ? isFilled(code) : false;
    const isError = !isAttemptPossible || !!errorText;

    return (
      <Form
        mods={{
          theme: 'standard',
          for: 'sms-code-confirmation',
        }}
        mix={[b('sms-code-confirmation-form')]}
      >
        <Form__Field>
          <SmsCodeFormField
            mods={{
              processing,
              disabled: !isAttemptPossible,
              valid: filled && isCodeCorrect,
              'resend-option': !isResendLimitReached && isAttemptPossible && !processing && !isCodeCorrect,
            }}
            id="sms-code-input"
            value={code}
            error={isError ? errorText : ''}
            resend={{
              pauseEndTime: newCodeRequestTime,
              onResendCode: onResend,
            }}
            onChange={this.onChange}
          />
        </Form__Field>
      </Form>
    );
  }
}

function isFilled(value) {
  const code = value.replace(/\D/g, '');
  return code.length === 4;
}

SmsCodeConfirmationForm.propTypes = propTypes;
SmsCodeConfirmationForm.defaultProps = defaultProps;

export default SmsCodeConfirmationForm;
