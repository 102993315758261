import TelField from '@kvantera/megafon-ui-components/tel-field';
import Form, { Form__Field, Form__Actions } from 'app/components/form';
import FormField from 'app/components/form-field';
import Button from '@kvantera/megafon-ui-components/button';

const VALID_MSISDN_LENGTH = 11;

const propTypes = {
  onPhoneNumberChange: PropTypes.func,
  onSubmit: PropTypes.func,
  processing: PropTypes.bool,
  errorText: PropTypes.string,
  phoneFieldLabel: PropTypes.string.isRequired,
};

const defaultProps = {
  onPhoneNumberChange: undefined,
  onSubmit: undefined,
  processing: false,
  errorText: undefined,
};

class OtpLoginForm extends React.Component {
  state = {
    valid: false,
    phone: '',
  };

  msisdn = '';

  onPhoneNumberChange = (phone) => {
    this.msisdn = phone.replace(/\D/g, '');
    this.setState({
      phone,
      valid: this.isValid(),
    });
    if (this.props.onPhoneNumberChange) this.props.onPhoneNumberChange();
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.props.onSubmit) this.props.onSubmit(this.msisdn);
  };

  isValid() {
    return this.msisdn.length === VALID_MSISDN_LENGTH;
  }

  render() {
    const {
      valid,
      phone,
    } = this.state;

    const {
      errorText,
      processing,
      phoneFieldLabel,
    } = this.props;

    return (
      <Form
        mods={{
          theme: 'standard',
          for: 'login',
        }}
        mix={[b('otp-login-form')]}
        onSubmit={this.onSubmit}
      >
        <Form__Field>
          <FormField
            id="phone-number-input"
            caption={phoneFieldLabel}
            mods={{
              validationState: errorText ? 'error' : undefined,
              hiddenCaption: true,
            }}
            error={errorText}
          >
            <TelField
              mods={{
                validationState: errorText ? 'error' : (valid && 'success') || undefined,
              }}
              value={phone}
              onChange={this.onPhoneNumberChange}
            />
          </FormField>
        </Form__Field>

        <Form__Actions>
          <Button
            mods={{
              type: 'primary',
              wide: true,
              disabled: !valid || processing,
            }}
            type="submit"
          >
            Войти
          </Button>
        </Form__Actions>
      </Form>
    );
  }
}

OtpLoginForm.propTypes = propTypes;
OtpLoginForm.defaultProps = defaultProps;

export default OtpLoginForm;
