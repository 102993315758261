import { connect } from 'react-redux';
import withFetcher from 'app/common/helpers/with-fetcher';

import {
  setMsisdn,
  setManualAuth,
  removeNextOtpRequestTime,
  setNextOtpRequestTime,
  removeLoginFormMsisdn,
  setSendOtpErrorText,
} from 'app/action-creators/auth';

import LoginConfirmationOtp from './login-confirmation-otp';

const mapStateToProps = state => ({
  loginFormMsisdn: state.auth.loginFormMsisdn,
  sendOtpErrorText: state.auth.sendOtpErrorText,
  nextOtpRequestTime: state.auth.nextOtpRequestTime,
});

const mapDispatchToProps = {
  setManualAuth,
  setMsisdn,
  removeNextOtpRequestTime,
  setNextOtpRequestTime,
  removeLoginFormMsisdn,
  setSendOtpErrorText,
};

export default withFetcher(connect(mapStateToProps, mapDispatchToProps)(LoginConfirmationOtp));
