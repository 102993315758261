import Dialog from 'app/components/dialog';
import DialogWindow from 'app/components/dialog-window';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dialogTitle: PropTypes.string.isRequired,
};

const LoginIdentificationDialog = (props) => {
  const {
    isOpen,
    onClose,
    children,
    dialogTitle,
  } = props;

  return (
    <Dialog
      mods={{
        theme: 'standard',
        type: isInsideIframe() ? 'embedded-login' : 'login',
      }}
      id="login-identification-dialog"
      isOpen={isOpen}
      onClose={() => { onClose('внешний клик'); }}
      initialFocusNode="#login-identification-dialog"
    >
      <DialogWindow
        mods={{
          theme: isInsideIframe() ? 'embedded' : 'standard',
          size: 'm',
          type: 'form',
        }}
        title={dialogTitle}
        onClose={() => { onClose('крестик'); }}
        closeButton={{
          mods: {
            onlyIcon: true,
          },
        }}
      >
        {children}
      </DialogWindow>
    </Dialog>
  );
};

LoginIdentificationDialog.propTypes = propTypes;

export default LoginIdentificationDialog;
