export default [
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "msisdn": {
              "type": "number"
            },
            "manual_auth": {
              "type": "boolean"
            }
          }
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "authRequired"
          ]
        },
        "redirectUrl": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "redirectUrl"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_send_push"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_send_push"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidMsisdn"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_send_push"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "tooManyRequests"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_send_push"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "inProgress"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mobile_id_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "send_otp"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "send_otp"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidMsisdn"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "send_otp"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "tooManyRequests"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "send_otp"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "otp_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "otp_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidMsisdn"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "otp_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "otp_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "tooManyRequests"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "otp_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidCode"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "partner_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "session_id": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "session_id"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "partner_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notTrustedReturnUrl"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "partner_auth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "logout"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "logout"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "nonManualAuth"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "logout"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "number"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidBalance"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_items"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "NewsPreview": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "category": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "category",
            "provider",
            "date"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/NewsPreview"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "category": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            },
            "subtitle": {
              "type": "string"
            },
            "content": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "category",
            "provider",
            "date",
            "subtitle",
            "content"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "FinancialNewsPreview": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "provider",
            "date"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/FinancialNewsPreview"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            },
            "subtitle": {
              "type": "string"
            },
            "content": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "provider",
            "date",
            "subtitle",
            "content"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "financial_news_items"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "NewsServicePreview": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "category": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "category",
            "provider",
            "date"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/NewsServicePreview"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "category": {
              "type": "string"
            },
            "provider": {
              "type": "string"
            },
            "date": {
              "type": "number"
            },
            "image": {
              "type": "string"
            },
            "subtitle": {
              "type": "string"
            },
            "content": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "category",
            "provider",
            "date",
            "subtitle",
            "content"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "news_service"
      },
      {
        "isRegExp": false,
        "value": "news"
      },
      {
        "isRegExp": true,
        "value": "^[^/]+$"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "anecdote"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "weather"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "WeatherShort": {
          "type": "object",
          "properties": {
            "condition": {
              "type": "string",
              "enum": [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17"
              ]
            },
            "dayTemp": {
              "type": "number"
            },
            "nightTemp": {
              "type": "number"
            }
          },
          "required": [
            "condition",
            "dayTemp",
            "nightTemp"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "locality": {
              "type": "string"
            },
            "currentWeather": {
              "type": "object",
              "properties": {
                "condition": {
                  "type": "string",
                  "enum": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17"
                  ]
                },
                "dayTemp": {
                  "type": "number"
                },
                "nightTemp": {
                  "type": "number"
                },
                "currentTemp": {
                  "type": "number"
                },
                "pressure": {
                  "type": "number"
                },
                "windSpeed": {
                  "type": "number"
                },
                "windDirection": {
                  "type": "number"
                }
              },
              "required": [
                "condition",
                "dayTemp",
                "nightTemp",
                "currentTemp",
                "pressure",
                "windSpeed",
                "windDirection"
              ]
            },
            "forecasts": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/WeatherShort"
              },
              "maxItems": 4
            }
          },
          "required": [
            "locality",
            "currentWeather",
            "forecasts"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "currency"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "usd": {
              "type": "object",
              "properties": {
                "rate": {
                  "type": "number"
                },
                "rateDiff": {
                  "type": "number"
                }
              },
              "required": [
                "rate"
              ]
            },
            "eur": {
              "type": "object",
              "properties": {
                "rate": {
                  "type": "number"
                },
                "rateDiff": {
                  "type": "number"
                }
              },
              "required": [
                "rate"
              ]
            }
          },
          "required": [
            "usd",
            "eur"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "weather": {
              "type": "boolean"
            },
            "currency": {
              "type": "boolean"
            }
          },
          "required": [
            "weather",
            "currency"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "sms_subs"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      },
      {
        "isRegExp": false,
        "value": "default"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "sign": {
              "type": "string",
              "enum": [
                "aries",
                "taurus",
                "gemini",
                "cancer",
                "leo",
                "virgo",
                "libra",
                "scorpio",
                "sagittarius",
                "capricorn",
                "aquarius",
                "pisces"
              ]
            },
            "subscribed": {
              "type": "boolean"
            },
            "text": {
              "type": "string"
            }
          },
          "required": [
            "sign",
            "subscribed",
            "text"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "horoscope"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind",
      "sign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string",
          "enum": [
            "subscribed",
            "unsubscribed",
            "suspended"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "astro"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "oracle"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "title": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "description": {
              "type": "string"
            }
          },
          "required": [
            "title",
            "image",
            "description"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "taro"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "rune": {
              "type": "string",
              "enum": [
                "fehu",
                "uruz",
                "thurisaz",
                "ansuz",
                "raido",
                "kauna",
                "gebo",
                "wunjo",
                "haglaz",
                "naudiz",
                "isaz",
                "jeran",
                "iwaz",
                "pertho",
                "algiz",
                "sowilo",
                "tiwaz",
                "berkanan",
                "ehwaz",
                "mannaz",
                "laukaz",
                "ingwaz",
                "dagaz",
                "othilia",
                "weird"
              ]
            },
            "description": {
              "type": "string"
            }
          },
          "required": [
            "rune",
            "description"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "fortune_telling"
      },
      {
        "isRegExp": false,
        "value": "runes"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "kind"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": [
            "object",
            "null"
          ],
          "properties": {
            "name": {
              "type": "string"
            },
            "gender": {
              "type": "string",
              "enum": [
                "female",
                "male"
              ]
            },
            "birth_date": {
              "type": "string"
            }
          },
          "required": [
            "name",
            "gender",
            "birth_date"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "DELETE",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "personal_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "zodiac"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userSign",
      "partnerSign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "zodiac"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userSign",
      "partnerSign"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "compatibilityNameNotFound"
          ]
        },
        "userNameFound": {
          "type": "boolean"
        },
        "partnerNameFound": {
          "type": "boolean"
        }
      },
      "required": [
        "status",
        "userNameFound",
        "partnerNameFound"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "compatibilityNotFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "name"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "partnerName"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "compatibility"
      },
      {
        "isRegExp": false,
        "value": "birth"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "dreamsWordNotFound"
          ]
        },
        "similarWord": {
          "type": "string"
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "word"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "type": "string"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "dreams"
      },
      {
        "isRegExp": false,
        "value": "words"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "letter"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "nameNotFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "meeting"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userName",
      "userDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "number_magic"
      },
      {
        "isRegExp": false,
        "value": "wedding"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "userDate",
      "partnerDate",
      "meetingDate"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_payment"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "source": {
              "type": "number"
            },
            "amount": {
              "type": "number"
            },
            "target": {
              "type": "object",
              "oneOf": [
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "phone"
                      ]
                    },
                    "number": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "card"
                      ]
                    },
                    "number": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "account"
                      ]
                    },
                    "number": {
                      "type": "string"
                    },
                    "bik": {
                      "type": "string"
                    },
                    "firstName": {
                      "type": "string"
                    },
                    "lastName": {
                      "type": "string"
                    },
                    "middleName": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number",
                    "firstName",
                    "lastName",
                    "middleName"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "custom"
                      ]
                    },
                    "gatewayId": {
                      "type": "string"
                    },
                    "fields": {
                      "type": "object"
                    }
                  },
                  "required": [
                    "kind",
                    "gatewayId",
                    "fields"
                  ]
                }
              ]
            },
            "uuid": {
              "type": "string"
            },
            "amountWithCommission": {
              "type": "number"
            },
            "status": {
              "type": "string",
              "enum": [
                "waiting",
                "rejected",
                "processing",
                "accepted",
                "completed",
                "failed",
                "refundRejected"
              ]
            },
            "createdAt": {
              "type": "number"
            },
            "processedAt": {
              "type": [
                "number",
                "null"
              ]
            },
            "targetDescription": {
              "type": "string"
            },
            "providerName": {
              "type": "string",
              "enum": [
                "cyberplat",
                "inplat",
                "mobidengi"
              ]
            }
          },
          "required": [
            "source",
            "amount",
            "target",
            "uuid",
            "amountWithCommission",
            "createdAt",
            "processedAt",
            "targetDescription",
            "providerName"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_payment"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_payment"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_payment"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest",
            "notMegaFon",
            "unsupportedPhoneNumber",
            "unsupportedCardType",
            "wrongAmount",
            "wrongCaptcha",
            "wrongTargetGateway",
            "wrongTargetParameters",
            "paymentsLimitExceeded",
            "temporaryUnavailable",
            "duplicatedPaymentParams"
          ]
        },
        "description": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "description"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "uuid"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "source": {
              "type": "number"
            },
            "amount": {
              "type": "number"
            },
            "target": {
              "type": "object",
              "oneOf": [
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "phone"
                      ]
                    },
                    "number": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "card"
                      ]
                    },
                    "number": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "account"
                      ]
                    },
                    "number": {
                      "type": "string"
                    },
                    "bik": {
                      "type": "string"
                    },
                    "firstName": {
                      "type": "string"
                    },
                    "lastName": {
                      "type": "string"
                    },
                    "middleName": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "kind",
                    "number",
                    "firstName",
                    "lastName",
                    "middleName"
                  ]
                },
                {
                  "properties": {
                    "kind": {
                      "type": "string",
                      "enum": [
                        "custom"
                      ]
                    },
                    "gatewayId": {
                      "type": "string"
                    },
                    "fields": {
                      "type": "object"
                    }
                  },
                  "required": [
                    "kind",
                    "gatewayId",
                    "fields"
                  ]
                }
              ]
            },
            "uuid": {
              "type": "string"
            },
            "amountWithCommission": {
              "type": "number"
            },
            "status": {
              "type": "string",
              "enum": [
                "waiting",
                "rejected",
                "processing",
                "accepted",
                "completed",
                "failed",
                "refundRejected"
              ]
            },
            "createdAt": {
              "type": "number"
            },
            "processedAt": {
              "type": [
                "number",
                "null"
              ]
            },
            "targetDescription": {
              "type": "string"
            },
            "providerName": {
              "type": "string",
              "enum": [
                "cyberplat",
                "inplat",
                "mobidengi"
              ]
            }
          },
          "required": [
            "source",
            "amount",
            "target",
            "uuid",
            "amountWithCommission",
            "createdAt",
            "processedAt",
            "targetDescription",
            "providerName"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "uuid"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "uuid"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "mm_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "uuid"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "operator"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "operatorName": {
              "type": "string"
            },
            "operatorId": {
              "type": "number"
            }
          },
          "required": [
            "operatorName",
            "operatorId"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "operator"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "operator"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "msisdn"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidMsisdn"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "widget"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "ParentalControlKid": {
          "type": "object",
          "properties": {
            "msisdn": {
              "type": "number"
            },
            "name": {
              "type": "string"
            },
            "is_verified": {
              "type": "boolean"
            },
            "metrics": {
              "type": "object",
              "properties": {
                "battery": {
                  "type": [
                    "number",
                    "null"
                  ]
                },
                "balance": {
                  "type": [
                    "number",
                    "null"
                  ]
                },
                "location": {
                  "type": [
                    "object",
                    "null"
                  ],
                  "properties": {
                    "latitude": {
                      "type": "number"
                    },
                    "longitude": {
                      "type": "number"
                    },
                    "error": {
                      "type": "number"
                    },
                    "time": {
                      "type": "number"
                    },
                    "address": {
                      "type": [
                        "object",
                        "null"
                      ],
                      "properties": {
                        "town": {
                          "type": "string"
                        },
                        "street": {
                          "type": "string"
                        },
                        "house": {
                          "type": "string"
                        },
                        "bulk": {
                          "type": "string"
                        },
                        "region": {
                          "type": [
                            "string",
                            "null"
                          ]
                        },
                        "building": {
                          "type": "string"
                        },
                        "metro": {
                          "type": "object",
                          "properties": {
                            "station": {
                              "type": "string"
                            },
                            "name_line": {
                              "type": "string"
                            },
                            "coordinates": {
                              "type": "object",
                              "properties": {
                                "latitude": {
                                  "type": "number"
                                },
                                "longitude": {
                                  "type": "number"
                                }
                              },
                              "required": [
                                "latitude",
                                "longitude"
                              ]
                            }
                          },
                          "required": [
                            "station",
                            "name_line",
                            "coordinates"
                          ]
                        }
                      },
                      "required": [
                        "town",
                        "street",
                        "house",
                        "region"
                      ]
                    }
                  },
                  "required": [
                    "latitude",
                    "longitude",
                    "error",
                    "time",
                    "address"
                  ]
                }
              },
              "required": [
                "battery",
                "balance",
                "location"
              ]
            },
            "settings": {
              "type": "object",
              "properties": {
                "sound": {
                  "type": "string",
                  "enum": [
                    "on",
                    "off",
                    "na"
                  ]
                },
                "internet": {
                  "type": "string",
                  "enum": [
                    "on",
                    "off",
                    "na"
                  ]
                }
              },
              "required": [
                "sound",
                "internet"
              ]
            },
            "click_url": {
              "type": "string"
            }
          },
          "required": [
            "msisdn",
            "name",
            "is_verified",
            "metrics",
            "settings",
            "click_url"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "kids": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/ParentalControlKid"
              }
            },
            "add_kid_url": {
              "type": "string"
            }
          },
          "required": [
            "kids",
            "add_kid_url"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "widget"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "widget"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "widget"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "parental_control"
      },
      {
        "isRegExp": false,
        "value": "widget"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "tracks"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "Track": {
          "type": "object",
          "properties": {
            "id": {
              "type": "number"
            },
            "title": {
              "type": "string"
            },
            "author": {
              "type": "string"
            },
            "uri": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "title",
            "author"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "tracks": {
              "type": "object",
              "properties": {
                "limited": {
                  "type": "array",
                  "items": {
                    "$ref": "#/definitions/Track"
                  }
                },
                "full": {
                  "type": "array",
                  "items": {
                    "$ref": "#/definitions/Track"
                  }
                }
              },
              "required": [
                "limited",
                "full"
              ]
            }
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "subscription": {
              "type": "object",
              "properties": {
                "status": {
                  "type": "string",
                  "enum": [
                    "subscribed",
                    "unsubscribed",
                    "suspended"
                  ]
                },
                "type": {
                  "type": "string",
                  "enum": [
                    "limited",
                    "full"
                  ]
                }
              },
              "required": [
                "status"
              ]
            }
          },
          "required": [
            "subscription"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "trava"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "noMoney"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "wp_negative_balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "balance": {
              "type": [
                "number",
                "null"
              ]
            },
            "tariff": {
              "type": [
                "string",
                "null"
              ]
            },
            "deferredPaymentNominals": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "number"
                  },
                  "amount": {
                    "type": "number"
                  },
                  "commission": {
                    "type": "number"
                  }
                },
                "required": [
                  "id",
                  "amount",
                  "commission"
                ]
              }
            }
          },
          "required": [
            "balance",
            "tariff",
            "deferredPaymentNominals"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "wp_negative_balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "wp_negative_balance"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_activation"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "requestId": {
              "type": "number"
            },
            "requestStatus": {
              "type": "string",
              "enum": [
                "ok",
                "inProgress"
              ]
            }
          },
          "required": [
            "requestId",
            "requestStatus"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_activation"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_activation"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "request_id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "requestStatus": {
              "type": "string",
              "enum": [
                "ok",
                "inProgress"
              ]
            }
          },
          "required": [
            "requestStatus"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "request_id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "deferred_payment_status"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "request_id"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "topup_url"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string"
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "topup_url"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "topup_url"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "celebrations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "Celebration": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string"
            },
            "description": {
              "type": "string"
            }
          },
          "required": [
            "name",
            "description"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "winter": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/Celebration"
              }
            },
            "spring": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/Celebration"
              }
            },
            "summer": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/Celebration"
              }
            },
            "autumn": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/Celebration"
              }
            },
            "upcoming": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "description"
              ]
            }
          },
          "required": [
            "winter",
            "spring",
            "summer",
            "autumn",
            "upcoming"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "celebrations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulation_types"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "CongratulationType": {
          "type": "object",
          "properties": {
            "id": {
              "type": "number"
            },
            "name": {
              "type": "string"
            },
            "occasions": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/CongratulationOccasion"
              }
            }
          },
          "required": [
            "id",
            "name",
            "occasions"
          ]
        },
        "CongratulationOccasion": {
          "type": "object",
          "properties": {
            "id": {
              "type": "number"
            },
            "name": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "name"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/CongratulationType"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "occasion"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "type": "string"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "occasion"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "occasion"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "occasion"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "occasion"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "type": "string"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "congratulations"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "game_types"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "GameType": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "enum": [
                "active",
                "office",
                "smart",
                "child"
              ]
            },
            "name": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "name"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/GameType"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "Game": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string"
            },
            "description": {
              "type": "string"
            }
          },
          "required": [
            "name",
            "description"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Game"
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "games"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "cocktails"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "name": {
                "type": "string"
              },
              "recipe": {
                "type": "string"
              }
            },
            "required": [
              "name",
              "recipe"
            ]
          }
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "cocktails"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "notFound"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "cocktails"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unsubscribed"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "cocktails"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "suspended"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "cocktails"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [
      "type"
    ],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "main_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "definitions": {
        "CongratulationType": {
          "type": "object",
          "properties": {
            "id": {
              "type": "number"
            },
            "name": {
              "type": "string"
            },
            "occasions": {
              "type": "array",
              "items": {
                "$ref": "#/definitions/CongratulationOccasion"
              }
            }
          },
          "required": [
            "id",
            "name",
            "occasions"
          ]
        },
        "GameType": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "enum": [
                "active",
                "office",
                "smart",
                "child"
              ]
            },
            "name": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "name"
          ]
        },
        "CongratulationOccasion": {
          "type": "object",
          "properties": {
            "id": {
              "type": "number"
            },
            "name": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "name"
          ]
        }
      },
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "object",
          "properties": {
            "holiday": {
              "type": "object",
              "properties": {
                "promo": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "name",
                    "description"
                  ]
                }
              },
              "required": [
                "promo"
              ]
            },
            "congratulation": {
              "type": "object",
              "properties": {
                "promo": {
                  "type": "string"
                },
                "types": {
                  "type": "array",
                  "items": {
                    "$ref": "#/definitions/CongratulationType"
                  }
                }
              },
              "required": [
                "promo",
                "types"
              ]
            },
            "game": {
              "type": "object",
              "properties": {
                "promo": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "name",
                    "description"
                  ]
                },
                "types": {
                  "type": "array",
                  "items": {
                    "$ref": "#/definitions/GameType"
                  }
                }
              },
              "required": [
                "promo",
                "types"
              ]
            }
          },
          "required": [
            "holiday",
            "congratulation",
            "game"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "main_info"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        },
        "result": {
          "type": "string",
          "enum": [
            "subscribed",
            "unsubscribed",
            "suspended"
          ]
        }
      },
      "required": [
        "status",
        "result"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "GET",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "unauthorized"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "holidays"
      },
      {
        "isRegExp": false,
        "value": "subscription"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "feedback"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "feedback"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "feedback"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "ask_astrologer"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "ok"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "ask_astrologer"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "invalidRequest"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  },
  {
    "type": "rest",
    "method": "POST",
    "urlSegments": [
      {
        "isRegExp": false,
        "value": "ask_astrologer"
      }
    ],
    "staticQueryParams": [],
    "requiredDynamicQueryParams": [],
    "definition": {
      "$schema": "http://json-schema.org/draft-04/schema#",
      "type": "object",
      "properties": {
        "status": {
          "type": "string",
          "enum": [
            "internalError"
          ]
        }
      },
      "required": [
        "status"
      ]
    }
  }
];
