import FormField from 'app/components/form-field';
import SmsCodeField from 'app/components/sms-code-field';
import uniqueId from '@kvantera/ui-helpers/unique-id';

import SmsCodeFormField__ResendOption from './__resend-option';

const propTypes = {
  mods: PropTypes.shape({
    disabled: PropTypes.bool,
    processing: PropTypes.bool,
    'resend-option': PropTypes.bool,
    valid: PropTypes.bool,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  caption: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  resend: PropTypes.shape({
    pauseEndTime: PropTypes.number.isRequired,
    onResendCode: PropTypes.func.isRequired,
  }).isRequired,
};

const defaultProps = {
  mods: {},
  id: undefined,
  name: null,
  caption: 'Код из SMS',
  value: undefined,
  error: null,
};

class SmsCodeFormField extends React.Component {
  constructor(props) {
    super(props);

    this.defaultId = uniqueId('sms-code-form-field');
  }

  render() {
    const {
      mods,
      id = this.defaultId,
      name,
      caption,
      value,
      error,
      onChange,
      resend,
    } = this.props;

    const invalid = !!error;

    return (
      <div className={b('sms-code-form-field', this.props)}>
        <FormField
          mods={{
            validationState: invalid ? 'error' : undefined,
            hiddenCaption: true,
          }}
          id={id}
          caption={caption}
          error={error}
          mix={[b('sms-code-form-field__control')]}
        >
          <SmsCodeField
            mods={{
              theme: 'standard',
              processing: mods.processing,
              disabled: mods.disabled,
              validationState: invalid ? 'error' : (mods.valid && 'success') || undefined,
            }}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
          />
        </FormField>
        {mods['resend-option'] && (
          <SmsCodeFormField__ResendOption
            pauseEndTime={resend.pauseEndTime}
            onResend={resend.onResendCode}
          />
        )}
      </div>
    );
  }
}

SmsCodeFormField.propTypes = propTypes;
SmsCodeFormField.defaultProps = defaultProps;

export default SmsCodeFormField;
