import MobileIdLoginConfirmationDialog from 'app/components/mobile-id-login-confirmation-dialog';
import MobileIdLoginExceededErrorDialog from 'app/components/mobile-id-login-exceeded-error-dialog';
import MobileIdLoginTimeoutErrorDialog from 'app/components/mobile-id-login-timeout-error-dialog';
import { getPartnerRedirectUrl, getPartnerReturnUrl } from 'app/common/helpers/auth';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';
import gtm from 'app/common/services/google-tag-manager';

const propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  loginFormMsisdn: PropTypes.string,
  setSendPushErrorText: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setManualAuth: PropTypes.func.isRequired,
  setMsisdn: PropTypes.func.isRequired,
  nextPushRequestTime: PropTypes.number.isRequired,
  setNextPushRequestTime: PropTypes.func.isRequired,
  removeNextPushRequestTime: PropTypes.func.isRequired,
  removeLoginFormMsisdn: PropTypes.func.isRequired,
  setSendOtpErrorText: PropTypes.func.isRequired,
  setNextOtpRequestTime: PropTypes.func.isRequired,
  fetcher: PropTypes.shape({
    post: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  loginFormMsisdn: undefined,
  required: false,
  noCloseButton: false,
};

const POLLING_INTERVAL = 5000;
const LOGIN_CONFIRMATION_DIALOG = 'LOGIN_CONFIRMATION_DIALOG';
const LOGIN_EXCEEDED_ERROR_DIALOG = 'LOGIN_EXCEEDED_ERROR_DIALOG';
const LOGIN_TIMEOUT_ERROR_DIALOG = 'LOGIN_TIMEOUT_ERROR_DIALOG';

class LoginConfirmation extends React.Component {
  state = {
    dialogId: LOGIN_CONFIRMATION_DIALOG,
    processing: false,
  };

  componentDidMount() {
    const {
      loginFormMsisdn,
      location,
      navigate,
    } = this.props;

    if (!loginFormMsisdn) {
      navigate({ ...location, pathname: '/login' }, { state: location.state });
    }

    this.startAuthStatusPolling();
  }

  componentWillUnmount() {
    this.stopAuthStatusPolling();
  }

  openDialog = (dialogId) => {
    this.setState({ dialogId });
  };

  closeDialog = () => {
    this.props.onClose();
  };

  resendPush = () => {
    const {
      loginFormMsisdn,
      setSendPushErrorText,
      setNextPushRequestTime,
      location,
      navigate,
      fetcher,
    } = this.props;

    gtm.onResendClick('Отправить новое Push-сообщение');

    this.setState({ processing: true });
    const params = { msisdn: loginFormMsisdn };
    const returnUrl = getPartnerReturnUrl();
    if (returnUrl) {
      params.return_url = returnUrl;
    }
    fetcher.post('/mobile_id_send_push', params).then(() => {
      this.setState({ processing: false });
      setNextPushRequestTime();
      this.startAuthStatusPolling();
      this.openDialog(LOGIN_CONFIRMATION_DIALOG);
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов push-уведомления',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      gtm.onLoginError(errorText);
      if (errorResponse.status === 'tooManyRequests') {
        this.openDialog(LOGIN_EXCEEDED_ERROR_DIALOG);
        return;
      }
      setSendPushErrorText(errorText);
      navigate({ ...location, pathname: '/login' }, { state: location.state });
    });
  };

  requestOtp = () => {
    const {
      fetcher,
      setSendOtpErrorText,
      setNextOtpRequestTime,
      location,
      navigate,
      loginFormMsisdn,
    } = this.props;

    const goToOtpConfirmation = (errorText = '') => {
      setNextOtpRequestTime();
      setSendOtpErrorText(errorText);
      navigate({ ...location, pathname: '/login-otp/confirmation' }, { state: location.state });
    };

    this.setState({ processing: true });
    const params = { msisdn: loginFormMsisdn };
    const returnUrl = getPartnerReturnUrl();
    if (returnUrl) {
      params.return_url = returnUrl;
    }
    fetcher.post('/send_otp', params).then(() => {
      goToOtpConfirmation();
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов кода подтверждения',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      this.setState({ processing: false });
      gtm.onLoginError(errorText);
      goToOtpConfirmation(errorText);
    });
  };

  showAuthError = () => {
    this.stopAuthStatusPolling();
    this.props.removeNextPushRequestTime();
    this.openDialog(LOGIN_TIMEOUT_ERROR_DIALOG);
  };

  getAuthStatus() {
    const {
      loginFormMsisdn,
      fetcher,
      onClose,
      setMsisdn,
      setManualAuth,
      removeNextPushRequestTime,
      removeLoginFormMsisdn,
    } = this.props;

    const onLogin = () => {
      gtm.onLoginConfirmationSuccess('push');
      setMsisdn(this.props.loginFormMsisdn);
      removeLoginFormMsisdn();
      removeNextPushRequestTime();
      setManualAuth(true);
      onClose();
    };

    fetcher.get(`/mobile_id_auth?msisdn=${loginFormMsisdn}`).then(() => {
      this.stopAuthStatusPolling();
      const returnUrl = getPartnerReturnUrl();
      if (returnUrl) {
        fetcher.post('/partner_auth', { return_url: returnUrl }).then((response) => {
          gtm.onLoginConfirmationSuccess('push');
          // Удаляем loginFormMsisdn и nextPushRequestTime из localStorage, но оставляем в redux.
          // Значение в redux нельзя удалять, так как иначе во время выполнения редиректа
          // компонент перерисовывается с пустыми значениями loginFormMsisdn и nextPushRequestTime
          // и выглядит неправильно.
          removeLoginFormMsisdn(true);
          removeNextPushRequestTime(true);
          if (isInsideIframe()) {
            window.parent.postMessage({
              messageId: 'authSuccess',
              sessionId: response.session_id,
            }, '*');
          } else {
            window.location = getPartnerRedirectUrl(returnUrl, response.session_id);
          }
        }, () => {
          onLogin();
        });
      } else {
        onLogin();
      }
    }, (errorResponse) => {
      if (errorResponse.status === 'inProgress') return;
      this.showAuthError();
      gtm.onLoginConfirmationError(errorResponse.status);
    });
  }

  startAuthStatusPolling() {
    this.getAuthStatus();
    this.authStatusPolling = setInterval(() => {
      this.getAuthStatus();
    }, POLLING_INTERVAL);
  }

  stopAuthStatusPolling() {
    clearInterval(this.authStatusPolling);
  }

  render() {
    const {
      dialogId,
      processing,
    } = this.state;

    const {
      loginFormMsisdn,
      nextPushRequestTime,
      required,
      noCloseButton,
      dialogTitle,
    } = this.props;

    return (
      <>
        {!!loginFormMsisdn && (
          <>
            <MobileIdLoginConfirmationDialog
              isOpen={dialogId === LOGIN_CONFIRMATION_DIALOG}
              onClose={closingMethod => {
                gtm.onCloseLoginDialog('Push-сообщение', closingMethod);
                this.closeDialog();
              }}
              processing={processing}
              msisdn={loginFormMsisdn}
              newMessageRequestTime={nextPushRequestTime}
              required={required}
              noCloseButton={noCloseButton}
              dialogTitle={dialogTitle}
              onResend={this.resendPush}
              onTimerReached={this.showAuthError}
            />
            <MobileIdLoginExceededErrorDialog
              isOpen={dialogId === LOGIN_EXCEEDED_ERROR_DIALOG}
              onClose={closingMethod => {
                gtm.onCloseLoginDialog('Способ входа', closingMethod);
                this.closeDialog();
              }}
              required={required}
              noCloseButton={noCloseButton}
              dialogTitle={dialogTitle}
              fallbackToOtp={this.requestOtp}
            />
            <MobileIdLoginTimeoutErrorDialog
              isOpen={dialogId === LOGIN_TIMEOUT_ERROR_DIALOG}
              onClose={closingMethod => {
                gtm.onCloseLoginDialog('Способ входа', closingMethod);
                this.closeDialog();
              }}
              processing={processing}
              resendPush={this.resendPush}
              required={required}
              noCloseButton={noCloseButton}
              dialogTitle={dialogTitle}
              fallbackToOtp={this.requestOtp}
            />
          </>
        )}
      </>
    );
  }
}

LoginConfirmation.propTypes = propTypes;
LoginConfirmation.defaultProps = defaultProps;

export default LoginConfirmation;
