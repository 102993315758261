import { connect } from 'react-redux';

import {
  performLogout,
} from 'app/action-creators/auth';

import Login from './login-identification';

const mapStateToProps = state => ({
  msisdn: state.auth.msisdn,
  isAuthorized: !!state.auth.msisdn,
  isManual: state.auth.isManual,
});

const mapDispatchToProps = {
  performLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
