const initialSettings = {
  apiBase: '/api',
  sentryDSN: `${window.location.protocol}//2325bf3eb7974686a92162e0e7eaf575@${window.location.host}/sentry/82`,
};

const settingsEnv = require('app.settings.env');

const settings = { ...initialSettings, ...settingsEnv.default };

export default settings;
