import { useLocation } from 'react-router-dom';

import Form, { Form__Note, Form__Actions } from 'app/components/form';
import FormattedMsisdn from '@kvantera/megafon-ui-components/formatted-msisdn';
import HiddenField from 'app/components/hidden-field';
import Button from '@kvantera/megafon-ui-components/button';
import Link from '@kvantera/megafon-ui-components/link';

const propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  msisdn: PropTypes.string.isRequired,
  canChangeMsisdn: PropTypes.bool.isRequired,
  isWaitingResponse: PropTypes.bool,
  onChangeMsisdn: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

const defaultProps = {
  isWaitingResponse: false,
};

const LoginIdentificationForm = (props) => {
  const {
    msisdn,
    canChangeMsisdn,
    onContinue,
    isWaitingResponse,
    onChangeMsisdn,
  } = props;

  const location = useLocation();

  const handleChangeMsisdn = (event) => {
    if (isWaitingResponse) {
      event.preventDefault();
    } else {
      onChangeMsisdn();
    }
  };

  return (
    <Form
      mods={{
        theme: 'standard',
      }}
      mix={[b('login-identification-form')]}
    >
      <Form__Note mods={{ for: 'notification' }}>
        {T`Вы можете продолжить как`}
        {' '}
        <FormattedMsisdn
          msisdn={msisdn}
          mix={b('login-identification-form__msisdn')}
        />
        {canChangeMsisdn && <span>&nbsp;или войти под другим номером телефона</span>}
      </Form__Note>

      <Form__Actions>
        <Button
          mods={{
            type: 'primary',
            wide: true,
            disabled: isWaitingResponse,
          }}
          onClick={onContinue}
        >
          Продолжить
        </Button>
      </Form__Actions>

      <HiddenField
        id="msisdn-input"
        value={msisdn}
      />

      {canChangeMsisdn && (
        <Form__Note mods={{ for: 'change-phone' }}>
          <Link
            mods={{
              theme: 'standard',
            }}
            to={{ ...location, pathname: '/login' }}
            onClick={handleChangeMsisdn}
          >
            Войти под другим номером
          </Link>
        </Form__Note>
      )}
    </Form>
  );
};

LoginIdentificationForm.propTypes = propTypes;
LoginIdentificationForm.defaultProps = defaultProps;

export default LoginIdentificationForm;
