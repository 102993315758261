import LoginDialog from 'app/components/login-dialog';
import MobileIdLoginForm from 'app/components/mobile-id-login-form';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  errorText: PropTypes.string,
  processing: PropTypes.bool,
  required: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  phoneFieldLabel: PropTypes.string.isRequired,
  noCloseButton: PropTypes.bool,
  onTermsOfServiceLinkClick: PropTypes.func,
};

const defaultProps = {
  onSubmit: undefined,
  errorText: undefined,
  processing: false,
  required: false,
  noCloseButton: false,
  onTermsOfServiceLinkClick: undefined,
};

const MobileIdLoginDialog = (props) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    errorText,
    processing,
    required,
    dialogTitle,
    phoneFieldLabel,
    noCloseButton,
    onTermsOfServiceLinkClick,
  } = props;

  return (
    <LoginDialog
      id="mobile-id-login-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={T`Введите номер вашего телефона, и мы отправим PUSH-сообщение для входа с помощью Мобильного ID.`}
      form={(
        <MobileIdLoginForm
          onSubmit={onSubmit}
          errorText={errorText}
          processing={processing}
          phoneFieldLabel={phoneFieldLabel}
          onTermsOfServiceLinkClick={onTermsOfServiceLinkClick}
        />
      )}
    />
  );
};

MobileIdLoginDialog.propTypes = propTypes;
MobileIdLoginDialog.defaultProps = defaultProps;

export default MobileIdLoginDialog;
