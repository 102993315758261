import * as Sentry from '@sentry/browser';
import schemas from 'api-schemas/schemas';
import { validateResponse, validationStatus } from '@funboxteam/api-validator';

export default function isValidResponse(method, url, response) {
  const { data, headers } = response;
  const requestId = headers && headers['x-request-id'];

  const result = validateResponse({
    method,
    url,
    data,
    schemas,
  });

  switch (result.status) {
    case validationStatus.invalid: {
      const errorText = `[api-validator] Ошибка валидации ${method} ${url}`;

      console.error(errorText);
      console.error(result.checkedSchemas);

      Sentry.withScope(scope => {
        scope.setExtra('responseData', data);
        scope.setExtra('checkedSchemas', result.checkedSchemas);
        scope.setExtra('requestId', requestId);
        Sentry.captureMessage(errorText, 'error');
      });

      return false;
    }

    case validationStatus.schemaNotFound: {
      const errorText = `[api-validator] Не найдена схема ${method} ${url}`;

      console.error(errorText);

      Sentry.withScope(scope => {
        scope.setExtra('responseData', data);
        scope.setExtra('requestId', requestId);
        Sentry.captureMessage(errorText, 'error');
      });

      return false;
    }

    // no default
  }

  return true;
}
