import axios, { isCancel } from 'axios';
import settings from 'app/app.settings';
import notifyAxiosError from 'app/common/helpers/notify-axios-error';

import store from 'app/app.store';
import logout from 'app/action-creators/logout';
import isValidResponse from 'app/api-validator';

const fetcher = {};
const methods = ['get', 'post', 'put', 'patch', 'delete', 'head'];
const basePath = BASE_PATH.slice(0, -1);
const basename = basePath ? `${basePath}${settings.apiBase}` : settings.apiBase;

methods.forEach(method => {
  fetcher[method] = (url, body = {}, heads = {}, cancelToken = undefined) => new Promise((resolve, reject) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...heads,
    };

    const parameters = {
      method,
      withCredentials: true,
      headers,
    };

    if (Object.keys(body).length) {
      parameters.data = body;
    }

    parameters.url = `${basename}${url}`;
    parameters.cancelToken = cancelToken;

    axios(parameters)
      .then(response => {
        const { data: result } = response;

        // Пустой ответ, скорее всего, означает, что загрузка страницы прекращена или
        // происходит навигация на другой сайт.
        // Игнорируем такой ответ, чтобы не получить ложную ошибку валидации.
        // См. https://jira.funbox.ru/browse/MYM-3083?focusedCommentId=1128658&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-1128658
        if (result === '') {
          return;
        }

        if (!isValidResponse(method.toUpperCase(), url, response)) {
          reject(new Error('Schema validation failed'));
          return;
        }

        if (result.status === 'ok') {
          resolve(result);
        } else {
          switch (result.status) {
            case 'unauthorized':
              store.dispatch(logout());
              break;
            // no default
          }

          reject(result);
        }
      })
      .catch(error => {
        if (!isCancel(error)) {
          notifyAxiosError(method, parameters.url);
          reject(error);
        }
      });
  });
});

export default fetcher;
