import TextField from '@kvantera/megafon-ui-components/text-field';

const propTypes = {
  mods: PropTypes.shape({
    for: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    masked: PropTypes.bool,
    focused: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    processing: PropTypes.bool,
    validationState: PropTypes.oneOf(['error', 'success']),
  }),
};

const defaultProps = {
  mods: {},
};

const SmsCodeField = React.forwardRef((props, ref) => {
  const { mods } = props;

  const localMods = {
    type: 'sms-code',
    masked: true,
  };

  const passedMods = Object.assign(localMods, mods);

  const passedProps = { ...props };
  delete passedProps.mods;

  return (
    <TextField
      mods={passedMods}
      placeholder="X X X X"
      type="tel"
      input={{
        mask: '9 9 9 9',
        maskPlaceholder: null,
        ref,
      }}
      {...passedProps}
    />
  );
});

SmsCodeField.propTypes = propTypes;
SmsCodeField.defaultProps = defaultProps;

export default SmsCodeField;
