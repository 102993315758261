import { useEffect } from 'react';
import _Dialog from '@kvantera/megafon-ui-components/dialog';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';

export default function Dialog(props) {
  useEffect(() => {
    if (isInsideIframe()) {
      setTimeout(() => {
        const dialogWindow = document.querySelector('.dialog__window');
        window.parent.postMessage({
          messageId: 'onHeightChange',
          height: dialogWindow.offsetHeight,
        }, '*');
      });
    }
  });
  return <_Dialog {...props}/>;
}
