import { connect } from 'react-redux';

import {
  fetchAuth,
} from 'app/action-creators/auth';

import LoginApp from './login-app';

const mapDispatchToProps = {
  fetchAuth,
};

export default connect(undefined, mapDispatchToProps)(LoginApp);
