import LoginDialog from 'app/components/login-dialog';
import OtpLoginForm from 'app/components/otp-login-form';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPhoneNumberChange: PropTypes.func,
  onSubmit: PropTypes.func,
  errorText: PropTypes.string,
  processing: PropTypes.bool,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  phoneFieldLabel: PropTypes.string.isRequired,
};

const defaultProps = {
  onPhoneNumberChange: undefined,
  onSubmit: undefined,
  errorText: undefined,
  processing: false,
  required: false,
  noCloseButton: false,
};

const OtpLoginDialog = (props) => {
  const {
    isOpen,
    onClose,
    onPhoneNumberChange,
    onSubmit,
    errorText,
    processing,
    noCloseButton,
    required,
    dialogTitle,
    phoneFieldLabel,
  } = props;

  return (
    <LoginDialog
      id="otp-login-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={T`Введите номер вашего телефона, и мы отправим на него SMS с кодом.`}
      form={(
        <OtpLoginForm
          onPhoneNumberChange={onPhoneNumberChange}
          onSubmit={onSubmit}
          errorText={errorText}
          processing={processing}
          phoneFieldLabel={phoneFieldLabel}
        />
      )}
    />
  );
};

OtpLoginDialog.propTypes = propTypes;
OtpLoginDialog.defaultProps = defaultProps;

export default OtpLoginDialog;
