import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import gtm from 'app/common/services/google-tag-manager';

const GtmLinkTracker = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.linkId) {
      return;
    }

    // Очищаем state, чтобы обновление страницы не считалось за нажатие кнопки.
    navigate(location, { state: undefined, replace: true });

    switch (location.state.linkId) {
      case 'changePhoneLink':
        gtm.onChangePhoneClick();
        break;

      default:
        break;
    }
  }, [location]);

  return null;
};

export default GtmLinkTracker;
