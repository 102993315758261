import { connect } from 'react-redux';
import withFetcher from 'app/common/helpers/with-fetcher';

import {
  setLoginFormMsisdn,
  setSendOtpErrorText,
  setNextOtpRequestTime,
} from 'app/action-creators/auth';

import LoginOtp from './login-otp';

const mapStateToProps = state => ({
  loginFormMsisdn: state.auth.loginFormMsisdn,
  sendOtpErrorText: state.auth.sendOtpErrorText,
  isAuthorized: !!state.auth.msisdn,
  nextOtpRequestTime: state.auth.nextOtpRequestTime,
});

const mapDispatchToProps = {
  setLoginFormMsisdn,
  setSendOtpErrorText,
  setNextOtpRequestTime,
};

export default withFetcher(connect(mapStateToProps, mapDispatchToProps)(LoginOtp));
