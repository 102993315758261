import {
  REMOVE_MSISDN,
} from 'app/constants/action-types';
import { localStorageSafelyRemoveItem } from 'app/common/helpers/safe-local-storage';

export const removeMsisdn = () => {
  localStorageSafelyRemoveItem('auth.msisdn');

  return { type: REMOVE_MSISDN };
};

const resetAuth = () => (dispatch) => {
  dispatch(removeMsisdn());
};

export default resetAuth;
