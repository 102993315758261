import SvgIcon from '@kvantera/core-ui-components/svg-icon';

import Dialog from 'app/components/dialog';
import DialogWindow, { DialogWindow__Description, DialogWindow__Actions } from 'app/components/dialog-window';
import LoginImage from 'app/assets/images/login-image.svg';
import isInsideIframe from 'app/common/helpers/is-inside-iframe';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  required: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  noCloseButton: PropTypes.bool,
  description: PropTypes.node,
  actions: PropTypes.node,
  form: PropTypes.node,
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  required: false,
  noCloseButton: false,
  description: null,
  actions: null,
  form: null,
};

const LoginDialog = (props) => {
  const {
    isOpen,
    onClose,
    required,
    dialogTitle,
    noCloseButton,
    description,
    actions,
    form,
    id,
  } = props;

  return (
    <Dialog
      mods={{
        theme: 'standard',
        type: isInsideIframe() ? 'embedded-login' : 'login',
        required,
      }}
      id={id}
      isOpen={isOpen}
      onClose={() => { onClose('внешний клик'); }}
    >
      <DialogWindow
        mods={{
          theme: isInsideIframe() ? 'embedded' : 'standard',
          type: !!form && 'form',
          size: 'm',
          for: 'login',
        }}
        title={dialogTitle}
        onClose={() => { onClose('крестик'); }}
        closeButton={{
          mods: {
            onlyIcon: true,
          },
        }}
        noCloseButton={noCloseButton}
      >
        {!!description && (
          <DialogWindow__Description>
            {description}
          </DialogWindow__Description>
        )}
        <SvgIcon mix={b('login-dialog__image')} symbol={LoginImage.id} width={118} height={118}/>
        {!!form && (
          <div className={b('login-dialog__form')}>
            {form}
          </div>
        )}
        {!!actions && (
          <DialogWindow__Actions>
            {actions}
          </DialogWindow__Actions>
        )}
      </DialogWindow>
    </Dialog>
  );
};

LoginDialog.propTypes = propTypes;
LoginDialog.defaultProps = defaultProps;

export default LoginDialog;
