import pushEvent from '../push-event';

/** Авторизация по хедеру (на всех страницах life.megafon.ru) */
export function onAuthByHeader() {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v262-e6',
    event_cat: 'authorization',
    event_name: 'sendSuccess',
    event_param: 'Успешная автоавторизация',
    auth_zone: 'auth',
  });
}

/** Успешный клик на кнопку "Войти" в окне авторизации */
export function onLoginSuccess() {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v134-e20',
    event_cat: 'logIn',
    event_name: 'sendInput1',
    event_param: 'Войти',
  });
}

/** Ошибка при клике на кнопку "Войти" в окне авторизации */
export function onLoginError(errorText) {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v134-e19',
    event_cat: 'logIn',
    event_name: 'sendError1',
    event_param: `Войти | ${errorText}`,
  });
}

/** Клик на крестик или внешний клик, для закрытия окон авторизации */
export function onCloseLoginDialog(dialogTitle, closingMethod) {
  pushEvent({
    event: 'addEvents_makeActions',
    event_id: 'd-v327-e3',
    event_cat: 'logIn',
    event_name: 'close',
    event_param: `${dialogTitle} / ${closingMethod}`,
  });
}

/** Клик на кнопку "Войти по SMS-коду" в окне авторизации */
export function onLoginWithSMSCodeClick() {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v327-e4',
    event_cat: 'logIn',
    event_name: 'submit0',
    event_param: 'Войти по SMS-коду',
  });
}

/** Успешный вход (при успешном вводе кода из SMS или успешном входе через push) */
export function onLoginConfirmationSuccess(confirmationType) {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v134-e22',
    event_cat: 'logIn',
    event_name: 'submit1',
    event_param: confirmationType,
  });
}

/** Ошибка ввода кода из SMS в окне авторизации */
export function onLoginConfirmationError(errorText) {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v134-e21',
    event_cat: 'logIn',
    event_name: 'sendError2',
    event_param: `sms | ${errorText}`,
  });
}

/** Клик на кнопку "Другой номер" в окне авторизации */
export function onLoginIdentificationChangeMsisdn() {
  pushEvent({
    event: 'addEvents_makeActions',
    event_id: 'd-v134-e23',
    event_cat: 'logIn',
    event_name: 'clickElement',
    event_param: 'Другой номер',
  });
}

/** Клик на кнопку "Продолжить" в окне авторизации */
export function onLoginIdentificationContinue() {
  pushEvent({
    event: 'addEvents_makeConversions',
    event_id: 'd-v134-e25',
    event_cat: 'logIn',
    event_name: 'submit2',
    event_param: 'Продолжить',
  });
}

/** Клик по кнопке "Войдите под другим номером" в окне авторизации */
export function onChangePhoneClick() {
  pushEvent({
    event: 'addEvents_makeActions',
    event_id: 'd-v327-e5',
    event_cat: 'logIn',
    event_name: 'clickElement',
    event_param: 'войдите под другим номером',
  });
}

/** Клик по "Отправить новое Push-сообщение"/ "Отправить код повторно" в окне авторизации */
export function onResendClick(buttonText) {
  pushEvent({
    event: 'addEvents_makeActions',
    event_id: 'd-v134-e24',
    event_cat: 'logIn',
    event_name: 'clickElement',
    event_param: buttonText,
  });
}

/** Клик на ссылку условий предоставления сервиса «Мобильный ID» */
export function onMobileIdTermsOfServiceLinkClick(linkText) {
  pushEvent({
    event: 'addEvents_useNavigations',
    event_id: 'd-v403-e3',
    event_cat: 'authorization',
    event_name: 'clickLink',
    event_param: linkText,
  });
}
