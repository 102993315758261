import { connect } from 'react-redux';
import withFetcher from 'app/common/helpers/with-fetcher';

import {
  setMsisdn,
  setManualAuth,
  removeNextPushRequestTime,
  setNextPushRequestTime,
  removeLoginFormMsisdn,
  setSendPushErrorText,
  setSendOtpErrorText,
  setNextOtpRequestTime,
} from 'app/action-creators/auth';

import LoginConfirmation from './login-confirmation';

const mapStateToProps = state => ({
  loginFormMsisdn: state.auth.loginFormMsisdn,
  sendPushErrorText: state.auth.sendPushErrorText,
  nextPushRequestTime: state.auth.nextPushRequestTime,
  setSendOtpErrorText: state.auth.setSendOtpErrorText,
  nextOtpRequestTime: state.auth.nextOtpRequestTime,
});

const mapDispatchToProps = {
  setManualAuth,
  setMsisdn,
  removeNextPushRequestTime,
  setNextPushRequestTime,
  removeLoginFormMsisdn,
  setSendPushErrorText,
  setSendOtpErrorText,
  setNextOtpRequestTime,
};

export default withFetcher(connect(mapStateToProps, mapDispatchToProps)(LoginConfirmation));
