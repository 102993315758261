import { useLocation } from 'react-router-dom';

import LoginDialog from 'app/components/login-dialog';
import Button from '@kvantera/megafon-ui-components/button';
import Link from '@kvantera/megafon-ui-components/link';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  changePhoneUrl: PropTypes.string.isRequired,
};

const defaultProps = {
  required: false,
  noCloseButton: false,
};

const OtpLoginExceededErrorDialog = (props) => {
  const {
    isOpen,
    onClose,
    required,
    noCloseButton,
    dialogTitle,
    returnUrl,
    changePhoneUrl,
  } = props;

  const location = useLocation();

  return (
    <LoginDialog
      id="otp-login-exceeded-error-dialog"
      isOpen={isOpen}
      onClose={onClose}
      required={required}
      noCloseButton={noCloseButton}
      dialogTitle={dialogTitle}
      description={T`Превышено количество попыток входа. Пожалуйста, попробуйте войти в сервис позже.`}
      actions={(
        <>
          <Link
            mods={{
              theme: 'standard',
            }}
            mix={b('otp-login-exceeded-error-dialog__change-phone')}
            to={{
              ...location,
              pathname: changePhoneUrl,
              state: { linkId: 'changePhoneLink' },
            }}
          >
            Войти под другим номером
          </Link>
          <Button
            mods={{
              type: 'primary',
              wide: true,
            }}
            href={returnUrl}
            text="Вернуться на сайт"
          />
        </>
      )}
    />
  );
};

OtpLoginExceededErrorDialog.propTypes = propTypes;
OtpLoginExceededErrorDialog.defaultProps = defaultProps;

export default OtpLoginExceededErrorDialog;
