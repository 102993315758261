import { Navigate } from 'react-router-dom';
import MobileIdLoginDialog from 'app/components/mobile-id-login-dialog';
import MobileIdLoginExceededErrorDialog from 'app/components/mobile-id-login-exceeded-error-dialog';
import MobileIdLoginGeneralErrorDialog from 'app/components/mobile-id-login-general-error-dialog';
import gtm from 'app/common/services/google-tag-manager';
import makeAbsoluteUrl from 'app/common/helpers/make-absolute-url';
import { getPartnerReturnUrl } from 'app/common/helpers/auth';

const propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  loginFormMsisdn: PropTypes.string,
  setLoginFormMsisdn: PropTypes.func.isRequired,
  sendPushErrorText: PropTypes.string,
  setSendPushErrorText: PropTypes.func.isRequired,
  setSendOtpErrorText: PropTypes.func.isRequired,
  setNextOtpRequestTime: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
  nextPushRequestTime: PropTypes.number.isRequired,
  setNextPushRequestTime: PropTypes.func.isRequired,
  fetcher: PropTypes.shape({
    post: PropTypes.func.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  phoneFieldLabel: PropTypes.string.isRequired,
};

const defaultProps = {
  loginFormMsisdn: undefined,
  isAuthorized: false,
  required: false,
  noCloseButton: false,
  sendPushErrorText: '',
};

const LOGIN_DIALOG = 'LOGIN_DIALOG';
const LOGIN_EXCEEDED_ERROR_DIALOG = 'LOGIN_EXCEEDED_ERROR_DIALOG';
const LOGIN_GENERAL_ERROR_DIALOG = 'LOGIN_GENERAL_ERROR_DIALOG';

class Login extends React.Component {
  state = {
    dialogId: LOGIN_DIALOG,
    processing: false,
  };

  componentDidUpdate(prevProps) {
    const { dialogId } = this.state;
    const { location: prevLocation } = prevProps;
    const { location: curLocation } = this.props;

    if (dialogId !== LOGIN_DIALOG && prevLocation.key !== curLocation.key) {
      this.openDialog(LOGIN_DIALOG);
    }
  }

  openDialog = (dialogId) => {
    this.setState({ dialogId });
  };

  closeDialog = () => {
    this.props.onClose();
  };

  handlePhoneNumberInput = () => {
    this.props.setSendPushErrorText('');
  };

  requestPush = (msisdn) => {
    const {
      fetcher,
      nextPushRequestTime,
      setSendPushErrorText,
      setNextPushRequestTime,
      setLoginFormMsisdn,
      location,
      navigate,
    } = this.props;

    const goToConfirmation = ({ newPushRequested }) => {
      gtm.onLoginSuccess();
      if (newPushRequested) {
        setNextPushRequestTime();
        setLoginFormMsisdn(msisdn);
      }
      navigate({ ...location, pathname: '/login/confirmation' }, { state: location.state });
    };

    if (msisdn === this.props.loginFormMsisdn && Date.now() < nextPushRequestTime) {
      goToConfirmation({ newPushRequested: false });
      return;
    }

    const params = { msisdn };
    const returnUrl = getPartnerReturnUrl();

    this.setState({ processing: true });
    setSendPushErrorText('');

    if (returnUrl) {
      params.return_url = returnUrl;
    }
    fetcher.post('/mobile_id_send_push', params).then(() => {
      goToConfirmation({ newPushRequested: true });
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов push-уведомления',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      this.setState({ processing: false });
      gtm.onLoginError(errorText);
      switch (errorResponse.status) {
        case 'tooManyRequests':
          setLoginFormMsisdn(msisdn);
          this.openDialog(LOGIN_EXCEEDED_ERROR_DIALOG);
          return;
        case 'internalError':
          setLoginFormMsisdn(msisdn);
          this.openDialog(LOGIN_GENERAL_ERROR_DIALOG);
          return;
        default:
          setSendPushErrorText(errorText);
          break;
      }
    });
  };

  requestOtp = () => {
    const {
      fetcher,
      setSendOtpErrorText,
      setNextOtpRequestTime,
      location,
      navigate,
      loginFormMsisdn,
    } = this.props;

    const goToOtpConfirmation = (errorText = '') => {
      gtm.onLoginSuccess();
      setNextOtpRequestTime();
      setSendOtpErrorText(errorText);
      navigate({ ...location, pathname: '/login-otp/confirmation' }, { state: location.state });
    };

    this.setState({ processing: true });
    const params = { msisdn: loginFormMsisdn };
    const returnUrl = getPartnerReturnUrl();
    if (returnUrl) {
      params.return_url = returnUrl;
    }
    fetcher.post('/send_otp', params).then(() => {
      goToOtpConfirmation();
    }, (errorResponse) => {
      const errors = {
        invalidMsisdn: 'Неверный номер или оператор не определен',
        tooManyRequests: 'Превышен лимит запросов кода подтверждения',
        default: 'Ошибка выполнения запроса',
      };
      const errorText = errors[errorResponse.status] || errors.default;
      this.setState({ processing: false });
      gtm.onLoginError(errorText);
      goToOtpConfirmation(errorText);
    });
  };

  render() {
    const {
      location,
      isAuthorized,
      required,
      noCloseButton,
      loginFormMsisdn,
      dialogTitle,
      phoneFieldLabel,
      sendPushErrorText,
    } = this.props;
    const {
      processing,
      dialogId,
    } = this.state;

    if (isAuthorized) {
      if (getPartnerReturnUrl()) {
        return (
          <Navigate
            to={{ ...location, pathname: '/login/identification' }}
            state={location.state}
          />
        );
      }
      window.location = makeAbsoluteUrl('/');
      return null;
    }

    return (
      <>
        <MobileIdLoginDialog
          isOpen={dialogId === LOGIN_DIALOG}
          onClose={closingMethod => {
            gtm.onCloseLoginDialog('Номер телефона', closingMethod);
            this.closeDialog();
          }}
          handlePhoneNumberInput={this.handlePhoneNumberInput}
          onSubmit={this.requestPush}
          errorText={sendPushErrorText}
          processing={processing}
          required={required}
          noCloseButton={noCloseButton}
          loginFormMsisdn={loginFormMsisdn}
          dialogTitle={dialogTitle}
          phoneFieldLabel={phoneFieldLabel}
          onTermsOfServiceLinkClick={gtm.onMobileIdTermsOfServiceLinkClick}
        />
        <MobileIdLoginExceededErrorDialog
          isOpen={dialogId === LOGIN_EXCEEDED_ERROR_DIALOG}
          onClose={closingMethod => {
            gtm.onCloseLoginDialog('Способ входа', closingMethod);
            this.closeDialog();
          }}
          required={required}
          noCloseButton={noCloseButton}
          dialogTitle={dialogTitle}
          processing={processing}
          fallbackToOtp={this.requestOtp}
        />
        <MobileIdLoginGeneralErrorDialog
          isOpen={dialogId === LOGIN_GENERAL_ERROR_DIALOG}
          onClose={closingMethod => {
            gtm.onCloseLoginDialog('Способ входа', closingMethod);
            this.closeDialog();
          }}
          dialogTitle={dialogTitle}
          processing={processing}
          fallbackToOtp={this.requestOtp}
        />
      </>
    );
  }
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
